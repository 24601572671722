import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import { Card } from '../../components';
import './style.scss';
import { adminService } from '../../../../services';

/**
 * @description React component for the Download Data card of the Admin Portal screen
 * @param {(string fileName, int month, int year) -> void} download function for downloading data csv with month and year query params
 * @param {Object} error represents downloadOutputDataError state variable
 * @param {() => any} onChange function called upon state variable changes
 */
const BatchRun = (props) => {
  const [upload, setUpload] = useState({});
  const [predictions, setPredictions] = useState([]);


  const changeData = () => {
    const data = {
      insect: {
        climate: {
          continental: upload.ClimateInsect_Continental,
          dry: upload.ClimateInsect_Dry,
          temperate: upload.ClimateInsect_Temperate,
          tropical: upload.ClimateInsect_Tropical,
        },
        ecozone: [],
        family: upload.insect_family,
        feedingGuild: upload.feedingGuild,
        genus: upload.insect.split('_')[0],
        order: upload.insect_order,
        species: upload.insect.split('_')[1],
      },
      tree: {
        hosts: upload.nativeHosts.split('; '),
      },
      user: {
        email: 'jc.23@dartmouth.edu',
        employer: 'DALI lab',
        name: 'Jessica',
        occupation: 'Student',
      },
    };
    return data;
  };

  useEffect(() => {
    const runTheModel = async () => {
      if (Object.keys(upload).length !== 0) {
        const data = changeData();
        const result = await adminService.batchModelRun(data);
        setPredictions(result.data.data);
      }
    };
    runTheModel();
  }, [upload]);

  const parseFile = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete(results) {
        setUpload(results.data[0]);
        setPredictions([]);
      },
    });
  };
  const getOutputData = () => {
    return predictions.map((prediction) => {
      return (
        {
          'infile.insect': prediction.infile.insect,
          'infile.Host_Type_of_Insect': prediction.infile.Host_Type_of_Insect,
          'infile.Diet_Breadth_of_Insect': prediction.infile.Diet_Breadth_of_Insect,
          Diet_Breadth_category: prediction.Diet_Breadth_category,
          'infile.feedingGuild': prediction.infile.feedingGuild,
          'infile.Scolytine': prediction.infile.Scolytine,
          'infile.sharedTribe': prediction.infile.sharedTribe,
          'infile.ClimateInsect_Tropical': prediction.infile.ClimateInsect_Tropical,
          'infile.ClimateInsect_Dry': prediction.infile.ClimateInsect_Dry,
          'infile.ClimateInsect_Temperate': prediction.infile.ClimateInsect_Temperate,
          'infile.ClimateInsect_Continental': prediction.infile.ClimateInsect_Continental,
          'infile.NA_Host_POWO': prediction.infile.NA_Host_POWO,
          'infile.NA_Host_Davey': prediction.infile.NA_Host_Davey,
          'infile.Host_Type_of_Tree': prediction.infile.Host_Type_of_Tree,
          'infile.Drought_Tolerance': prediction.infile.Drought_Tolerance,
          'infile.Shade_Tolerance': prediction.infile.Shade_Tolerance,
          'infile.divergTime': prediction.infile.divergTime,
          'infile.ClimateNATree_Tropical': prediction.infile.ClimateNATree_Tropical,
          'infile.ClimateNATree_Dry': prediction.infile.ClimateNATree_Dry,
          'infile.ClimateNATree_Temperate': prediction.infile.ClimateNATree_Temperate,
          'infile.ClimateNATree_Continental': prediction.infile.ClimateNATree_Continental,
          climateMatch: prediction.climateMatch,
          hostLikely: prediction.infile.treeLikelihood,
          modelClassName: prediction.modelClassName,
          baseRisk_prob: prediction.baseRisk_prob,
          nsubmod: prediction.nsubmod,
          insectTraitsPR_resid: prediction.insectTraitsPR_resid,
          hostTraitsPR_resid: prediction.hostTraitsPR_resid,
          treeRelatednessPR_resid: prediction.treeRelatednessPR_resid,
          insectRelatednessPR_resid: prediction.insectRelatednessPR_resid,
          combinedPR_logit: prediction.combinedPR_logit,
          combinedPR_prob: prediction.combinedPR_prob,
        }
      );
    });
  };

  return (
    <Card name="Batch Run" className="fit-card">
      <div className="batch-run-container">
        <p>
          Please upload a csv to run the model with.
        </p>
        <p>
          <Link className="link" target="_blank" to={{ pathname: 'https://docs.google.com/spreadsheets/d/1nFV9xEPyEXhKBj9l5eo3RVYAKiBKQBEwznhP4IW6koI/edit?usp=sharing' }}>
            The CSV must have the same column names and format as this example one
          </Link>
        </p>
        <input type="file" onChange={parseFile} />
        {predictions?.length > 0 ? (
          <CSVLink
            className="download-text"
            data={getOutputData()}
            filename={`${predictions[0]?.infile?.insect}.csv`}
          >
            Output
          </CSVLink>
        ) : null}
      </div>
    </Card>
  );
};

export default BatchRun;
