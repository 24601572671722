import React from 'react';

/**
 * @description React table header item component for the Admin Portal Admin List container
 * @param {string} item content of the header item
 */
const TableHeadItem = ({ item }) => {
  return (
    <th title={item}>
      {item}
    </th>
  );
};

export default TableHeadItem;
