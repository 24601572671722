import { ActionTypes } from '../actions';

const initialState = {
  isModelRunning: false,
  results: [],
  updates: [],
};

const PredictionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CLEAR_PREDICTION_UPDATES:
      return { ...state, updates: action.payload };
    case ActionTypes.FETCH_PREDICTION_RESULTS:
      if (action.payload !== state.results[state.results.length - 1]) {
        return { ...state, results: [...state.results, action.payload] };
      } else {
        return { ...state };
      }
    case ActionTypes.FETCH_PREDICTION_UPDATE:
      return { ...state, updates: [...state.updates, action.payload] };
    case ActionTypes.SET_MODEL_RUNNING:
      return { ...state, isModelRunning: action.payload };
    default:
      return state;
  }
};

export default PredictionsReducer;
