import React from 'react';
import { HeaderMenu, FooterMenu } from '../../components';
import './style.scss';

const Resources = (_props) => {
  return (
    <div>
      <HeaderMenu />
      <div className="resources-root">
        <div className="resources-content-container">
          <div>
            <h1 className="resources-title">Helpful Resources</h1>
            <p className="resources-subtitle">
              For the insect of interest, users will need to enter some specific information for the
              model to run. Here for a list of helpful references to obtain this information.
            </p>
          </div>
          <div className="resources-card">
            <h2 className="resources-card-title">Native Range Host Trees</h2>
            <p className="resources-card-text">
              The best place to find host records is through the peer-reviewed literature. However,
              there are now many online resources that synthesize host records from the literature
              and put them in searchable databases. A few great resources are listed below, but are
              certainly not all encompassing. Note that some of the databases are specific to
              certain taxa (e.g., scale insects) or locations (e.g., Great Britain).
            </p>
            <ul className="resources-card-list">
              <li>
                Aphid Identification
                (2021). <a href="https://influentialpoints.com/Gallery/Aphid_genera.htm">https://influentialpoints.com/Gallery/Aphid_genera.htm</a>
              </li>
              <li>
                Australian vascular plants
                directory: <a href="https://biodiversity.org.au/">https://biodiversity.org.au/</a>
              </li>
              <li>
                Blackman RL, Eastop VF (1994). Aphids on the World&apos;s Trees: An identification
                and information guide. New York, NY: John Wiley &
                Sons. <a href="http://www.aphidsonworldsplants.info/">http://www.aphidsonworldsplants.info/</a>
              </li>
              <li>
                CABI (2021). Invasive Species Compendium. Wallingford, UK: CAB
                International. <a href="www.cabi.org/isc">www.cabi.org/isc</a>
              </li>
              <li>
                Database of British Insects and their Foodplants
                (2021). <a href="http://www.brc.ac.uk/dbif/hosts.aspx">http://www.brc.ac.uk/dbif/hosts.aspx</a>
              </li>
              <li>
                Ellis, WN. 2021. Plant parasites of Europe: leafminers, galls, and
                fungi. <a href="https://bladmineerders.nl/">https://bladmineerders.nl/</a>
              </li>
              <li>
                García Morales M, Denno BD, Miller DR, Miller GL, Ben-Dov Y, Hardy NB (2016).
                ScaleNet: A literature-based model of scale insect biology and
                systematics. <a href="http://scalenet.info">http://scalenet.info</a>
              </li>
              <li>
                Global Invasive Species Database
                (2009). <a href="http://issg.org/database/reference/index.asp">http://issg.org/database/reference/index.asp</a>
              </li>
              <li>
                Poelen JH, Simons JD, & Mungall CJ (2014). Global Biotic Interactions: An open
                infrastructure to share and analyze species-interaction datasets. Ecological
                Informatics. https://doi.org/10.1016/j.ecoinf.2014.08.005. Global Biotic
                Interactions searchable
                database: <a href="https://www.globalbioticinteractions.org/browse">https://www.globalbioticinteractions.org/browse</a>
              </li>
              <li>
                Pickering J (2011). Discover Life. Retrieved
                from <a href="http://www.discoverlife.org">http://www.discoverlife.org</a>
              </li>
              <li>
                Robinson GS, Ackery PR, Kitching IJ, Baccaloni GW, Hernández LM (2010). HOSTS – a
                database of the world&apos;s Lepidopteran hostplants. London, UK: Natural History
                Museum. Retrieved
                from <a href="http://www.nhm.ac.uk/hosts">http://www.nhm.ac.uk/hosts</a>
              </li>
            </ul>
          </div>
          <div className="resources-card">
            <h2 className="resources-card-title">Insect Taxonomy</h2>
            <p className="resources-card-text">
              You will have to enter the insect’s Order, Family, Genus, and Species. Here are a few
              resources you could check to find updated taxonomic information:
            </p>
            <ul className="resources-card-list">
              <li>
                BugGuide <a href="https://bugguide.net/node/view/15740">https://bugguide.net/node/view/15740</a>
              </li>
              <li>
                Integrated Taxonomic Information System
                (ITIS) <a href="https://www.itis.gov/">https://www.itis.gov/</a>
              </li>
              <li>
                NCBI Taxonomy
                Browser <a href="https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=131567">https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=131567</a>
              </li>
              <li>
                Open Tree of
                Life <a href="https://tree.opentreeoflife.org/about/open-tree-of-life">https://tree.opentreeoflife.org/about/open-tree-of-life</a>
              </li>
              <br />Regional:
              <li>
                CSIRO Australian
                Insects <a href="http://www.ces.csiro.au/aicn/name_s/b_1.htm">http://www.ces.csiro.au/aicn/name_s/b_1.htm</a>
              </li>
              <li>
                Atlas of living
                Australia <a href="https://www.ala.org.au/">https://www.ala.org.au/</a>
              </li>
              <li>
                Fauna Europea <a href="https://fauna-eu.org/">https://fauna-eu.org/</a>
              </li>
              <li>
                Tadauchi, O., & Inoue, H. (2007). Database MOKUROKU Based on A check list of
                Japanese insects. Entomological Laboratory, Faculty of Agriculture, Kyushu
                University. <a href="http://konchudb.agr.agr.kyushu-u.ac.jp/mokuroku/">http://konchudb.agr.agr.kyushu-u.ac.jp/mokuroku/</a>
              </li>
            </ul>
          </div>
          <div className="resources-card">
            <h2 className="resources-card-title">Insect Feeding Guild</h2>
            <p className="resources-card-text">
              Feeding guild within and among insect species is variable. No one source provides a
              full picture of the feeding guild(s) for each insect species, but some of the
              databases used to find native range host trees may be useful for determining feeding
              guild. We also recommend searching for the insect in Google, Google Scholar, etc. to
              determine which parts of the tree the insect feeds on to ascertain feeding guild. If
              an insect has more than one feeding guild (e.g., it damages/feeds on the roots and
              foliage), select the feeding guild that has the highest impact on the host tree.
            </p>
          </div>
          <div className="resources-card">
            <h2 className="resources-card-title">Native Range and Köppen Climate</h2>
            <p className="resources-card-text">
              In addition to some of the native range host tree databases, the following
              resources may be useful for identifying the native range of an insect. After
              identifying the native range, go to the Köppen Climate Classification Wikipedia
              page map to identify the climate in the native range of the insect.
            </p>
            <ul className="resources-card-list">
              <li>
                CABI, 2021. Invasive Species Compendium. Wallingford, UK: CAB
                International. <a href="www.cabi.org/isc">www.cabi.org/isc</a>
              </li>
              <li>
                GBIF <a href="https://www.gbif.org">https://www.gbif.org</a>
              </li>
              <li>
                Köppen Climate
                Classification <a href="https://en.wikipedia.org/wiki/K%C3%B6ppen_climate_classification">https://en.wikipedia.org/wiki/K%C3%B6ppen_climate_classification</a>
              </li>
            </ul>
          </div>
        </div>
        <FooterMenu />
      </div>
    </div>
  );
};

export default Resources;
