import React from 'react';
import { Card, Table } from '../../components';

/**
 * @description React component for the Admin List card of the Admin Portal screen
 * @param {Object[]} data array that holds objects of table body row items and row id
 * @param {Object} error represents error state variable
 * @param {(string) => any} onClickButton function that is passed the item.username value of the a row's item
 */
const DownloadData = ({
  data,
  error,
  onClickButton = () => {},
}) => {
  return (
    <Card name="Admin List" className="big-card">
      <Table data={data} onClickButton={onClickButton} />
      <p id="error-message" className="error-text">{error ? error.text : null}</p>
    </Card>
  );
};

export default DownloadData;
