import { connect } from 'react-redux';
import {
  setAllUserInfo,
  setUserEmail,
  setUserEmployer,
  setUserName,
  setUserOccupation,
} from '../../../../state/actions';

import BasicInfo from './component';

const mapStateToProps = (state) => {
  const {
    form: {
      user,
    },
  } = state;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUserInfo: (userInfo) => {
      dispatch(setAllUserInfo(userInfo));
    },
    setUserEmail: (email) => {
      dispatch(setUserEmail(email));
    },
    setUserEmployer: (employer) => {
      dispatch(setUserEmployer(employer));
    },
    setUserName: (name) => {
      dispatch(setUserName(name));
    },
    setUserOccupation: (occupation) => {
      dispatch(setUserOccupation(occupation));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasicInfo);
