import { optionsService } from '../../services';
import ActionTypes from './types';

/**
 * @description action creator fetching ecozones data
 */
export const fetchInsectEcozones = async (dispatch) => {
  try {
    const data = await optionsService.fetchInsectEcozones();
    dispatch({ type: ActionTypes.FETCH_INSECT_ECOZONES, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch insect ecozones data',
      },
    });
  }
};

/**
 * @description action creator for fetching insect families data
 */
export const fetchInsectFamilies = async (dispatch) => {
  try {
    const data = await optionsService.fetchInsectFamilies();
    dispatch({ type: ActionTypes.FETCH_INSECT_FAMILIES, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch insect families data',
      },
    });
  }
};

/**
 * @description action creator for fetching feeding guilds data
 */
export const fetchInsectFeedingGuilds = async (dispatch) => {
  try {
    const data = await optionsService.fetchInsectFeedingGuilds();
    dispatch({ type: ActionTypes.FETCH_INSECT_FEEDING_GUILDS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch insect feeding guilds data',
      },
    });
  }
};

/**
 * @description action creator for fetching insect genera data
 */
export const fetchInsectGenera = async (dispatch) => {
  try {
    const data = await optionsService.fetchInsectGenera();
    dispatch({ type: ActionTypes.FETCH_INSECT_GENERA, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch insect genera data',
      },
    });
  }
};

/**
 * @description action creator for fetching insect orders data
 */
export const fetchInsectOrders = async (dispatch) => {
  try {
    const data = await optionsService.fetchInsectOrders();
    dispatch({ type: ActionTypes.FETCH_INSECT_ORDERS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch insect orders data',
      },
    });
  }
};

/**
 * @description action creator for fetching insect genera data
 */
export const fetchTreeGenera = async (dispatch) => {
  try {
    const data = await optionsService.fetchTreeGenera();
    dispatch({ type: ActionTypes.FETCH_TREE_GENERA, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch tree genera data',
      },
    });
  }
};

/**
 * @description action creator for fetching tree native hosts data
 * @param {string} genus - the name of trees genus
 */
export const fetchTreeHosts = genus => async (dispatch) => {
  try {
    const data = await optionsService.fetchTreeHosts(genus);
    dispatch({ type: ActionTypes.FETCH_TREE_HOSTS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_OPTIONS_ERROR,
      payload: {
        error,
        text: 'Failed to fetch tree hosts data',
      },
    });
  }
};
