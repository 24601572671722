import { ActionTypes } from '../actions';

const initialErrorState = {
  addAdminError: null,
  addAdminSuccess: null,
  adminError: null,
  editAdminError: null,
  downloadOutputDataError: null,
  loginUserError: null,
  resetPasswordError: null,
  resetPasswordSuccess: null,
};

const initialState = {
  allAdminUsers: [],
  currentUser: {},
  ...initialErrorState,
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_USER_INFO:
      return { ...state, currentUser: action.payload };

    case ActionTypes.SET_ALL_ADMIN_USERS:
      return { ...state, allAdminUsers: action.payload };

    case ActionTypes.SET_ADMIN_DOWNLOAD_ERROR:
      return { ...state, downloadOutputDataError: action.payload };

    case ActionTypes.SET_ADMIN_ADD_ERROR:
      return { ...state, addAdminError: action.payload };

    case ActionTypes.SET_ADMIN_ADD_SUCCESS:
      return { ...state, addAdminSuccess: action.payload };

    case ActionTypes.SET_ADMIN_EDIT_ERROR:
      return { ...state, editAdminError: action.payload };

    case ActionTypes.SET_ADMIN_ERROR:
      return { ...state, adminError: action.payload };

    case ActionTypes.SET_ADMIN_LOGIN_ERROR:
      return { ...state, loginUserError: action.payload };

    case ActionTypes.SET_RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordError: action.payload };

    case ActionTypes.SET_RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: action.payload };

    case ActionTypes.CLEAR_ADMIN_DATA:
      return initialState;

    case ActionTypes.CLEAR_ADMIN_ERRORS:
      return { ...state, ...initialErrorState };

    default:
      return state;
  }
};

export default AdminReducer;
