import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import Rodal from 'rodal';
import moment from 'moment';

import InsectImg from '../../../../assets/insect.svg';
import BlueHistogram from '../../../../assets/icons/histogram-blue.svg';
import WhiteHistogram from '../../../../assets/icons/histogram-white.svg';
import BlueTable from '../../../../assets/icons/table-blue.svg';
import WhiteTable from '../../../../assets/icons/table-white.svg';
import NewTab from '../../../../assets/icons/new-tab.svg';
import Download from '../../../../assets/icons/download.svg';
import RiskBar from '../../../../assets/risk-bar.png';

import OutputTable from './components/table';
import OutputHistogram from './components/histogram';

import './style.scss';

const ModelOutput = (props) => {
  const [outputType, setOutputType] = useState('table');
  const [highRiskVisible, setHighRiskVisibility] = useState(false);

  const {
    activeTab,
    allInsectNames,
    allInsectOrders,
    allInsectFamilies,
    allInsectTreeHosts,
    allInsectNativeRanges,
    isModelRunning,
    predictionResults,
    predictionUpdates,
    setActiveTab,
  } = props;

  const renderButtons = () => {
    return (
      <div className="tab-buttons-container">
        {allInsectNames.map((insect, i) => {
          return (
            <button type="button" className={`tab-button ${activeTab === i ? 'active-tab' : ''}`} onClick={() => setActiveTab(i)}>{insect.split('_').join(' ')}</button>
          );
        })}
        <button
          type="button"
          className={`tab-button clear-inputs-button ${activeTab === -1 ? 'active-tab' : ''}`}
          onClick={() => { setActiveTab(-1); }}
        >New Pest
        </button>

      </div>
    );
  };

  const renderOutputs = () => {
    if (isModelRunning && predictionUpdates.length > 0) {
      const { progress } = predictionUpdates[predictionUpdates.length - 1];
      return (
        <div>
          <div className="insect-loading">
            <div className="gradient-container" style={{ backgroundImage: `linear-gradient(#E4E4E4 ${100 - progress * 100}%, #ABE152 ${110 - progress * 100}%)` }} />
            <img className="insect-img" src={InsectImg} alt="outline of a beetle" />

          </div>

          <p className="progress-message">{predictionUpdates[predictionUpdates.length - 1].message}</p>
        </div>

      );
    } else if (!isModelRunning && predictionResults.length > 0 && predictionResults[activeTab]?.predictions?.length) {
      return outputType === 'table' ? <OutputTable /> : <OutputHistogram />;
    } else {
      return (
        <p className="model-empty-state">No results yet. Please enter inputs and run the model to get a risk report for your insect.</p>
      );
    }
  };

  useEffect(() => {
    renderOutputs();
  }, [activeTab]);

  const renderDisplayToggle = () => {
    return (
      !isModelRunning && predictionResults.length > 0 && predictionResults[activeTab]?.predictions?.length ? (
        <div className="toggle-container">
          <div className={outputType === 'table' ? 'white-outline' : ''} onClick={() => setOutputType('table')}>
            <img src={outputType === 'table' ? BlueTable : WhiteTable} className="toggle-image" alt="table icon" />
            { outputType === 'table'
              ? <p className="toggle-text">Table View</p>
              : null
            }
          </div>
          <div className={outputType === 'histogram' ? 'white-outline' : ''} onClick={() => setOutputType('histogram')}>
            <img src={outputType === 'histogram' ? BlueHistogram : WhiteHistogram} className="toggle-image" alt="histogram icon" />
            { outputType === 'histogram'
              ? <p className="toggle-text">Graph View</p>
              : null
            }
          </div>
        </div>
      ) : null
    );
  };

  const getInputData = () => {
    const prediction = predictionResults[activeTab].predictions[0];
    return (
      [
        {
          'infile.order': allInsectOrders[activeTab],
          'infile.family': allInsectFamilies[activeTab],
          'infile.insect': allInsectNames[activeTab], // genus_species
          'infile.feedingGuild': prediction.infile.feedingGuild,
          'infile.ClimateInsect_Tropical': prediction.infile.ClimateInsect_Tropical,
          'infile.ClimateInsect_Dry': prediction.infile.ClimateInsect_Dry,
          'infile.ClimateInsect_Temperate': prediction.infile.ClimateInsect_Temperate,
          'infile.ClimateInsect_Continental': prediction.infile.ClimateInsect_Continental,
          'infile.BiogeogInsect_PalearcticEurope': allInsectNativeRanges[activeTab].palearctic_europe,
          'infile.BiogeogInsect_PalearcticAsia': allInsectNativeRanges[activeTab].palearctic_asia,
          'infile.BiogeogInsect_Neotropical': allInsectNativeRanges[activeTab].neotropical,
          'infile.BiogeogInsect_Afrotropical': allInsectNativeRanges[activeTab].afrotropical,
          'infile.BiogeogInsect_Australasian': allInsectNativeRanges[activeTab].autralasian,
          'infile.BiogeogInsect_Indomalayan': allInsectNativeRanges[activeTab].indomalayan,
          'infile.NaturalHostSpecies': allInsectTreeHosts[activeTab],
        },
      ]
    );
  };

  const getOutputData = () => {
    return predictionResults[activeTab].predictions.map((prediction) => {
      return (
        {
          'infile.insect': prediction.infile.insect,
          'infile.Host_Type_of_Insect': prediction.infile.Host_Type_of_Insect,
          'infile.Diet_Breadth_of_Insect': prediction.infile.Diet_Breadth_of_Insect,
          Diet_Breadth_category: prediction.Diet_Breadth_category,
          'infile.feedingGuild': prediction.infile.feedingGuild,
          'infile.Scolytine': prediction.infile.Scolytine,
          'infile.sharedTribe': prediction.infile.sharedTribe,
          'infile.ClimateInsect_Tropical': prediction.infile.ClimateInsect_Tropical,
          'infile.ClimateInsect_Dry': prediction.infile.ClimateInsect_Dry,
          'infile.ClimateInsect_Temperate': prediction.infile.ClimateInsect_Temperate,
          'infile.ClimateInsect_Continental': prediction.infile.ClimateInsect_Continental,
          'infile.NA_Host_POWO': prediction.infile.NA_Host_POWO,
          'infile.NA_Host_Davey': prediction.infile.NA_Host_Davey,
          'infile.Host_Type_of_Tree': prediction.infile.Host_Type_of_Tree,
          'infile.Drought_Tolerance': prediction.infile.Drought_Tolerance,
          'infile.Shade_Tolerance': prediction.infile.Shade_Tolerance,
          'infile.divergTime': prediction.infile.divergTime,
          'infile.ClimateNATree_Tropical': prediction.infile.ClimateNATree_Tropical,
          'infile.ClimateNATree_Dry': prediction.infile.ClimateNATree_Dry,
          'infile.ClimateNATree_Temperate': prediction.infile.ClimateNATree_Temperate,
          'infile.ClimateNATree_Continental': prediction.infile.ClimateNATree_Continental,
          climateMatch: prediction.climateMatch,
          hostLikely: prediction.infile.treeLikelihood,
          modelClassName: prediction.modelClassName,
          baseRisk_prob: prediction.baseRisk_prob,
          nsubmod: prediction.nsubmod,
          insectTraitsPR_resid: prediction.insectTraitsPR_resid,
          hostTraitsPR_resid: prediction.hostTraitsPR_resid,
          treeRelatednessPR_resid: prediction.treeRelatednessPR_resid,
          insectRelatednessPR_resid: prediction.insectRelatednessPR_resid,
          combinedPR_logit: prediction.combinedPR_logit,
          combinedPR_prob: prediction.combinedPR_prob,
        }
      );
    });
  };

  const getFilteredOutputData = () => {
    const likelihoodvals = { High: 0, Medium: 1, Low: 2 };
    return predictionResults[activeTab].predictions
      .filter(prediction => prediction.combinedPR_prob !== 'NA')
      .sort((a, b) => likelihoodvals[a.infile.treeLikelihood] - likelihoodvals[b.infile.treeLikelihood] || b.combinedPR_prob - a.combinedPR_prob)
      .map((prediction) => {
        return (
          {
            'infile.NA_Host_POWO': prediction.infile.NA_Host_POWO,
            'infile.NA_Host_Davey': prediction.infile.NA_Host_Davey,
            hostLikely: prediction.infile.treeLikelihood,
            combinedPR_prob: prediction.combinedPR_prob,
          }
        );
      });
  };

  const renderDownloadButtons = () => {
    return (
      !isModelRunning && predictionResults.length > 0 && predictionResults[activeTab]?.predictions?.length ? (
        <div className="download-container">
          Downloads:
          <Link
            className="download-text"
            to={{ pathname: 'https://drive.google.com/file/d/1NXs6ZYw1m88IXL72XnGcD6ogVWsnEp2n/view?usp=sharing' }}
            target="_blank"
          >
            <img src={NewTab} className="download-image" alt="download icon" />
            Metadata
          </Link>
          <CSVLink
            className="download-text"
            data={getInputData()}
            filename={`${moment().format('YYYYMMDDhmm')}.${allInsectNames[activeTab].replace(' ', '_')}-input.csv`}
          >
            <img src={Download} className="download-image" alt="download icon" />
            Input
          </CSVLink>
          <CSVLink
            className="download-text"
            data={getOutputData()}
            filename={`${moment().format('YYYYMMDDhmm')}.${allInsectNames[activeTab].replace(' ', '_')}-output.csv`}
          >
            <img src={Download} className="download-image" alt="download icon" />
            Output
          </CSVLink>
          <CSVLink
            className="download-text"
            data={getFilteredOutputData()}
            filename={`${moment().format('YYYYMMDDhmm')}.${allInsectNames[activeTab].replace(' ', '_')}-output-filtered.csv`}
          >
            <img src={Download} className="download-image" alt="download icon" />
            Output (filtered)
          </CSVLink>
        </div>
      ) : null
    );
  };

  return (
    <div className="output-card-container">
      <div className="heading-container">
        <div className="info-container">
          <h1>{allInsectNames[activeTab]?.split('_').join(' ')} Risk Report</h1>
          { outputType === 'table'
            ? (
              <div className="table-instructions">
                <p className="table-instruction">For each North American tree species (leftmost column), two outputs are provided.</p>
                <p className="table-instruction"><b>Likelihood of host use</b> is determined as follows:</p>
                <p className="table-instruction">High: the insect feeds on the same plant GENUS in its native range</p>
                <p className="table-instruction">Medium: the insect feeds on the same plant FAMILY, but not the same GENUS</p>
                <p className="table-instruction">Low: the insect doesn&apos;t feed on this FAMILY or GENUS in its native range</p>
                <p className="table-instruction">Tree species name is shaded if the climate zone of insect does not match that of the tree</p>
                <p className="table-instruction"><b>Probability of high impact</b> represents the combined output of all models used in determining the prediction.  See Science page for details</p>
                <p><b>High Impact:</b> an insect species that causes mortality of their host plants at population or regional scales,
                  disrupting ecological systems, and causing serious environmental or socio-economic harm
                </p>
              </div>
            )
            : <p>This is a histogram of the distribution of the all the risk probabilities calculated in this run.</p>}
        </div>
        {renderDisplayToggle()}

      </div>
      {
        outputType === 'table'
          ? (
            <div>
              <img src={RiskBar} className="risk-bar" alt="gradient going from red for high risk to blue for low risk" />
              <p className="high-risk-text" onClick={() => setHighRiskVisibility(true)}>How to interpret the risk predictions</p>
            </div>
          )
          : null
      }

      <div className="output-container">
        {renderOutputs()}
      </div>
      <Rodal
        animation="door"
        visible={highRiskVisible}
        onClose={() => setHighRiskVisibility(false)}
        width={400}
        height={340}
      >
        <div className="high-risk-modal">
          <p><b>How to interpret the risk predictions</b></p>
          <p>
            Values are the estimated probability that this insect
            would be a high impact invasive on this tree species.
            For context, here are the highest risk probabilities
            calculated by this model for four non-native insects
            that have turned out to be notoriously high impact
            invasives. Hemlock woolly adelgid: 0.10; emerald ash
            borer: 0.22; Lymantria dispar: 0.39; redbay ambrosia
            beetle: 0.59.
          </p>

        </div>
      </Rodal>
      {renderButtons()}
      {renderDownloadButtons()}
    </div>
  );
};

export default ModelOutput;
