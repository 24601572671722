import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderMenu, FooterMenu } from '../../components';
import './style.scss';

const PublicationsAndCode = (_props) => {
  return (
    <div>
      <HeaderMenu />
      <div className="publications-root">
        <div className="publications-content-container">
          <h1 className="publications-title">Publications and Code</h1>
          <div className="publications-flex-container">
            <div className="refereed-publications-container">
              <div className="refereed-publications-card">
                <div className="card-text-container">
                  <p className="card-title">Refereed Publications</p>
                  <p className="card-text">
                    Schulz, A.N., A.M. Mech, M.P. Ayres, K.J.K. Gandhi, N.P. Havill, D.A. Herms, A. Hoover,
                    R.A. Hufbauer, A.M. Liebhold, T.D. Marsico, K.F. Raffa, P.C. Tobin, D.R. Uden, and K.A.
                    Thomas. 2021. Predicting non-native insect impact: focusing on the trees to see the forest.
                  </p>
                  <p className="card-text">
                    Biological Invasions. https://doi.org/10.1007/s10530-021-02621-5
                    Schulz, A.N., A.M. Mech, C.R. Allen, M.P. Ayres, K.J.K. Gandhi, J. Gurevitch, N.P. Havill,
                    D.A. Herms, R.A. Hufbauer, A.M. Liebhold, K.F. Raffa, M.J. Raupp, K.A. Thomas, P.C. Tobin,
                    and T.D. Marsico. 2020. The impact is in the details: evaluating a standardized protocol and
                    scale for determining non-native insect impact. NeoBiota 55: 61-83.
                    https://doi.org/10.3897/neobiota.55.38981
                  </p>
                  <p className="card-text">
                    Mech, A.M., K.A. Thomas, T.D. Marsico, D.A. Herms, C.R. Allen, M.P. Ayres, K.J.K. Gandhi,
                    J. Gurevitch, N.P. Havill, R.A. Hufbauer, A.M. Liebhold, K.F. Raffa, A.N. Schulz, D.R. Uden,
                    and P.C. Tobin. 2019. Evolutionary history predicts high-impact invasions by herbivorous
                    insects. Ecology and Evolution 9(21): 12216-12230. https://doi.org/10.1002/ece3.5709
                  </p>
                </div>
              </div>
            </div>
            <div className="published-datasets-container">
              <div className="published-datasets-card">
                <div className="card-text-container">
                  <p className="card-title">Published Datasets</p>
                  <p className="card-text">
                    Mech, A.M, A.M. Hoover, A.N. Schulz, B.F. Barnes, K.S. Boyd, L. Durden, N.P. Havill, R.A.
                    Hufbauer, A.M. Liebhold, T.D. Marsico, K.F. Raffa, C. Singareddy, E. Teach, P.C. Tobin, A.W.
                    Wolf, and K.A. Thomas. 2020. Traits and Factors Catalog (TRAFAC): Hardwood specialists of
                    North America. U.S. Geological Survey data release. https://doi.org/10.5066/P9FT7C1O
                  </p>
                  <p className="card-text">
                    Mech, A.M, K.A. Thomas, N.P. Havill, A.N. Schulz, and P.C. Tobin. 2020. Traits and Factors
                    Catalog (TRAFAC): Conifer specialists of North America. U.S. Geological Survey data release.
                    https://doi.org/10.5066/P9CLFQMI
                  </p>
                </div>
              </div>
            </div>
            <div className="fact-sheet-container">
              <div className="fact-sheet-card">
                <div className="card-text-container">
                  <p className="card-title">Fact Sheet</p>
                  <p className="card-text">
                    Durden, L.A., A.N. Schulz, A.M. Mech, and K.A. Thomas. 2020. A not so sudden impact:
                    historical relations between conifers and insects can help predict damage by nonnative insects.
                    U.S. Geological Survey Fact Sheet 2020-3039. 4 p. https://doi.org/10.3133/fs20203039
                  </p>
                </div>
              </div>
            </div>
            <div className="r-code-container">
              <div className="r-code-card">
                <div className="card-text-container">
                  <p className="card-title">R Code</p>
                  <p className="card-text">
                    Here is a link to the R code written by Dr. Matt Ayres to calculate a non-native insect’s probability of impact:
                  </p>
                  <p className="card-text">
                    <Link className="link" target="_blank" to={{ pathname: 'https://docs.google.com/document/d/1Mksrcav5ipYZEJ4VUZQ3hCOiJaRO1xFZGIq4T17jAEA/edit?usp=sharing' }}>
                      https://docs.google.com/document/d/1Mksrcav5ipYZEJ4VUZQ3hCOiJaRO1xFZGIq4T17jAEA/edit?usp=sharing
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="phylogenetic-container">
              <div className="phylogenetic-card">
                <div className="card-text-container">
                  <p className="card-title">Phylogenetic Diversity Code</p>
                  <p className="card-text">
                    Here is a link to the code used to calculate phylogenetic diversity:
                  </p>
                  <p className="card-text">
                    <Link className="link" target="_blank" to={{ pathname: 'https://docs.google.com/document/d/1SM2ucPBsR-zARKhTQRgoy1Vdz5REB9L4oTburZSseS4/edit?usp=sharing' }}>
                      https://docs.google.com/document/d/1SM2ucPBsR-zARKhTQRgoy1Vdz5REB9L4oTburZSseS4/edit?usp=sharing
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="shortest-divergence-container">
              <div className="shortest-divergence-card">
                <div className="card-text-container">
                  <p className="card-title">Shortest Divergence Time Code</p>
                  <p className="card-text">
                    Here is a link to the code used to calculate shortest divergence time:
                  </p>
                  <p className="card-text">
                    <Link className="link" target="_blank" to={{ pathname: 'https://docs.google.com/document/d/1vdcgROAvyfX7IWfvL6wDuEUUmY0gIyiwzijSfNbL97Y/edit?usp=sharing' }}>
                      https://docs.google.com/document/d/1vdcgROAvyfX7IWfvL6wDuEUUmY0gIyiwzijSfNbL97Y/edit?usp=sharing
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    </div>
  );
};

export default PublicationsAndCode;
