import React from 'react';

/**
 * @description React container component for Admin Portal content, utilizes props.children
 * @param {string} className css class name
 */
const Card = (props) => {
  return (
    <div className={`card ${props.className}`}>
      {props.name ? <h1 className="card-name">{props.name}</h1> : null}
      {props.children}
    </div>
  );
};

export default Card;
