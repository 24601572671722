import React from 'react';
import SimpleDropdown from '../simple-dropdown';

const monthOptions = [
  {
    key: 'January',
    text: 'January',
    value: 1,
  },
  {
    key: 'February',
    text: 'February',
    value: 2,
  },
  {
    key: 'March',
    text: 'March',
    value: 3,
  },
  {
    key: 'April',
    text: 'April',
    value: 4,
  },
  {
    key: 'May',
    text: 'May',
    value: 5,
  },
  {
    key: 'June',
    text: 'June',
    value: 6,
  },
  {
    key: 'July',
    text: 'July',
    value: 7,
  },
  {
    key: 'August',
    text: 'August',
    value: 8,
  },
  {
    key: 'September',
    text: 'September',
    value: 9,
  },
  {
    key: 'October',
    text: 'October',
    value: 10,
  },
  {
    key: 'November',
    text: 'November',
    value: 11,
  },
  {
    key: 'December',
    text: 'December',
    value: 12,
  },
];

/**
 * @description React month-selection dropdown menu component for the Admin Portal Download Data container
 * @param {String} className css class name
 * @param {(int) => any} setMonth function that is passed the integer value of the currently selected month
 */
const MonthSelect = ({ className, setMonth }) => {
  return (
    <SimpleDropdown
      className={className}
      placeholder="-- Month --"
      options={monthOptions}
      setFunc={setMonth}
    />
  );
};

export default MonthSelect;
