import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import { HeaderMenu, FooterMenu } from '../../components';
import CompositeModel from './components';

import academicPdf from '../../assets/docs/Ecology-and-Evolution---2019-Mech---Evolutionary-history-predicts-high-impact-invasions-by-herbivorous-insects.pdf';

import ModelExplainer from '../../assets/model-explanation.svg';
import CompositeModelImg from '../../assets/final-composite-model.svg';
import ModelValues from '../../assets/model-variables.svg';
import Model11 from '../../assets/model-1-1.svg';
import Model12 from '../../assets/model-1-2.svg';
import Model21 from '../../assets/model-2-1.svg';
import Model22 from '../../assets/model-2-2.svg';
import Model31 from '../../assets/model-3-1.svg';
import Model32 from '../../assets/model-3-2.svg';
import Model33 from '../../assets/model-3-3.svg';
import Model41 from '../../assets/model-4-1.svg';
import Model42 from '../../assets/model-4-2.svg';
import Model51 from '../../assets/model-5-1.svg';
import Model52 from '../../assets/model-5-2.svg';
import MathModel from '../../assets/math-model.svg';

import InsectTrait from '../../assets/animations/insect-traits.json';
import TreeTrait from '../../assets/animations/tree-traits.json';
import InsectHistory from '../../assets/animations/insect-history.json';
import TreeHistory from '../../assets/animations/tree-history.json';
import Phylogeny from '../../assets/animations/phylogeny.json';
import DeepDive from '../../assets/animations/deep-dive.gif';

import PhylogenyImg from '../../assets/img/phylogeny.png';
import DeepDiveImg from '../../assets/img/deep-dive.png';

const Science = (props) => {
  const [displayPhylogeny, togglePhylogeny] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [displayDeepDive, toggleDeepDive] = useState(true);

  const lottieOptions = (fileName) => {
    return ({
      loop: false,
      autoplay: true,
      animationData: fileName,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
  };

  return (
    <div>
      <HeaderMenu />
      <div className="science-container">
        <div className="tree-background-img" />
        <div className="header-rectangle-background">
          <div className="header-text-container">
            <p className="header-title">The Science Behind the Model</p>
            <p className="header-about">
              Most arriving insects never become pests so what determines whether or not a non-native insect becomes a pest?
              This model predicts the probability of an arriving non-native insect
              becoming a high impact pest.
            </p>
            <p className="header-info">
              Academic Paper (<Link
                className="link"
                target="_blank"
                to={academicPdf}
              >PDF</Link>): <Link
                className="link"
                target="_blank"
                to={{ pathname: 'https://doi.org/10.1002/ece3.5709' }}
              >Evolutionary history predicts high-impact invasions by herbivorous insects</Link>.
            </p>
          </div>
        </div>
        <div className="what-is-model-container">
          <div className="what-is-model-heading-background">
            <p className="subsection-heading what-is-model-heading">What is a model?</p>
          </div>
          <div className="what-is-model-content">
            <p className="subsection-content">A model uses input data, determined by hypotheses, to produce predictions.</p>
            <img src={ModelExplainer} alt="three boxes labeled input data, model, and predictions" />
          </div>
        </div>
        <div className="hypothesis-container">
          <div className="hypothesis-heading-background">
            <p className="subsection-heading hypothesis-heading">What are our hypotheses?</p>
          </div>
          <div className="hypothesis-content">
            <div className="small-animations-container">
              <div className="animation-item">
                <Lottie
                  options={lottieOptions(InsectTrait)}
                  height={90}
                  width={90}
                />
                <p>Traits of the insect</p>
              </div>
              <div className="animation-middle-row">
                <div className="animation-item">
                  <Lottie
                    options={lottieOptions(TreeTrait)}
                    height={90}
                    width={90}
                  />
                  <p>Traits of the North American host tree(s). </p>
                </div>
                <div className="animation-item">
                  <Lottie
                    options={lottieOptions(InsectHistory)}
                    height={90}
                    width={90}
                  />
                  <p>Insect evolutionary history (relatedness)</p>
                </div>
              </div>
              <div className="animation-item">
                <Lottie
                  options={lottieOptions(TreeHistory)}
                  height={90}
                  width={90}
                />
                <p>Tree evolutionary history (relatedness)</p>
              </div>
            </div>
            <div className="hypothesis-content-container">
              <p className="subsection-content">
                We came up with many different hypotheses as to what might influence a non-native insect.
                For example, the insect’s feeding guild or number of generations could influence impact.
                Or perhaps the growth rate or shade tolerance of the attacked tree could influence its susceptibility to the insect.
              </p>
              <p className="subsection-content">
                Overall, there were dozens of hypotheses, but they all fell within one of these four categories.
                We hypothesized that an insect’s impact is probably driven by more than one of the categories,
                so we evaluated each separately as individual submodels and then combined them into a single composite model to get the final predicted risk for each North American tree assessed.
              </p>
            </div>

          </div>
        </div>
        <div className="variables-container">
          <div className="variables-heading-background">
            <p className="subsection-heading variables-heading">What variables did we test?</p>
          </div>
          <div className="variables-content">
            <p className="subsection-content">For each hypothesis, we tested a set of variables, and selected a subset of these to be used in the final submodels.</p>
            <img src={ModelValues} alt="a table listing all the values tested" />
          </div>
        </div>
        <div className="final-model-container">
          <p className="final-model-heading">The Final Composite Model</p>
          <p className="subsection-content">
            Each submodel determined whether there were any traits or factors within that category that significantly influenced a non-native insect’s risk of high impact.
          </p>
          <img className="final-model-diagram" src={CompositeModelImg} alt="the four components of the model" />
          <div className="phylogeny-container">
            <p className="final-model-heading">A Phylogeny Detour</p>
            <div className="phylogeny-diagram-container">
              {displayPhylogeny ? (
                <Lottie
                  options={lottieOptions(Phylogeny)}
                  height={400}
                  width={500}
                  eventListeners={[{ eventName: 'complete', callback: () => togglePhylogeny() }]}
                />
              ) : (
                <img src={PhylogenyImg} alt="phylogeny" className="phylogeny-img" />
              )}
            </div>
          </div>
        </div>
        <div className="deep-dive-container">
          <p className="final-model-heading">The Final Composite Model: A Deeper Dive</p>
          <p className="subsection-content">
            We found that not all composite models are created equal – results varied based on which type of insect (generalist vs. non-generalist)
            and which type of tree (conifer vs. hardwood) was being evaluated. The right one for your insect will be determined based on what
            information you enter as the user. In addition, predictions will only be produced for the tree type(s) associated with the non-native insect -
            if it only utilizes conifers in its native range, predicted risks will only be for North American conifer trees (each with its own unique row of information).
          </p>
          {displayDeepDive ? (
            <img className="deep-dive-animation" src={DeepDive} alt="loading..." />
          ) : (
            <img src={DeepDiveImg} alt="deep dive" className="deep-dive-img" />
          )}
        </div>
        <div className="submodel-container">
          <p className="final-model-heading">How does each composite model calculate probability? </p>
          <CompositeModel
            modelNumber={1}
            naTreeProperty="hardwood"
            insectProperty={['has narrow host plant breadth ', 'is a bark beetle (Scolytinae)']}
            modelText1="The model uses the tree relatedness and insect relatedness models. "
            modelText2="Base risk = 32% chance of high impact. "
            radioButtons={[false, false, true, true]}
            graphs={[null, null, Model11, Model12]}
          />
          <CompositeModel
            modelNumber={2}
            naTreeProperty="hardwood"
            insectProperty={['has narrow host plant breadth ', 'is NOT a bark beetle (Scolytinae)']}
            modelText1="The model uses the tree relatedness and insect relatedness models. "
            modelText2="Base risk = 6% chance of high impact. "
            radioButtons={[true, false, true, false]}
            graphs={[Model21, null, Model22, null]}
          />
          <CompositeModel
            modelNumber={3}
            naTreeProperty="hardwood"
            insectProperty={['has wide host plant breadth ']}
            modelText1="The model uses the tree relatedness and insect relatedness models. "
            modelText2="Base risk = 16% chance of high impact. "
            radioButtons={[true, true, true, false]}
            graphs={[Model31, Model32, Model33, null]}
          />
          <CompositeModel
            modelNumber={4}
            naTreeProperty="conifer"
            insectProperty={['is a sapfeeder']}
            modelText1="The model uses the tree relatedness and insect relatedness models. "
            modelText2="Base risk = 7% chance of high impact. "
            radioButtons={[false, true, true, false]}
            graphs={[null, Model41, Model42, null]}
          />
          <CompositeModel
            modelNumber={5}
            naTreeProperty="conifer"
            insectProperty={['is NOT sapfeeder']}
            modelText1="The model uses the tree relatedness and insect relatedness models. "
            modelText2="Base risk = 7% chance of high impact. "
            radioButtons={[false, true, true, false]}
            graphs={[null, Model51, Model52, null]}
          />
        </div>
        <div className="math-model-container">
          <div className="math-model-card">
            <div className="math-model-text-container">
              <p className="math-model-title">Composite Risk (R) Mathematical Model</p>
              <p className="math-model-text">
                The composite prediction combines all applicable sub-models (m = 1 to 3) to estimate the composite risk (R) for each combination of
                non-native insect (i) and each North American tree species (t).
              </p>
              <p className="math-model-text">
                See the <Link className="link" target="_blank" to={{ pathname: 'https://doi.org/10.1002/ece3.5709' }}>academic paper</Link> for more details on the math.
              </p>
              <p className="math-model-text">
                A description about the statistics function
                called <Link className="link" target="_blank" to={{ pathname: 'https://en.wikipedia.org/wiki/Logit' }}>logit</Link> can be found on Wikipedia.
              </p>
            </div>
            <div className="math-model-equation-container">
              <img src={MathModel} alt="composite risk math model equation" />
            </div>
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
};

export default Science;
