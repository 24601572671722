import { ActionTypes } from '../actions';
import OPTIONS from '../../constants/options';

const initialState = {
  insect: {
    climate: OPTIONS.CLIMATES_OBJECT,
    ecozone: OPTIONS.ECOZONES_OBJECT,
    family: '',
    feedingGuild: '',
    genus: '',
    order: '',
    species: '',
  },
  tree: {
    hosts: [],
  },
  user: {
    email: '',
    employer: '',
    name: '',
    occupation: '',
  },
  allInsects: {
    activeTab: 0,
    allNames: [],
    allOrders: [],
    allFamilies: [],
    allFeedingGuilds: [],
    allClimates: [],
    allNativeRanges: [],
    allTreeHosts: [],
  },
};

const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TREE_HOST:
      return { ...state, tree: { hosts: [...state.tree.hosts, action.payload] } };
    case ActionTypes.REMOVE_TREE_HOST:
      return { ...state, tree: { hosts: [...state.tree.hosts.filter(p => p !== action.payload)] } };
    case ActionTypes.SET_ACTIVE_TAB:
      if (action.payload === -1) {
        return {
          ...state,
          insect: {
            climate: OPTIONS.CLIMATES_OBJECT,
            ecozone: OPTIONS.ECOZONES_OBJECT,
            family: '',
            feedingGuild: '',
            genus: '',
            order: '',
            species: '',
          },
          tree: { hosts: [] },
          allInsects: {
            ...state.allInsects,
            activeTab: action.payload,
          },
        };
      } else {
        return {
          ...state,
          insect: {
            climate: state.allInsects.allClimates[action.payload],
            ecozone: state.allInsects.allNativeRanges[action.payload],
            family: state.allInsects.allFamilies[action.payload],
            feedingGuild: state.allInsects.allFeedingGuilds[action.payload],
            genus: state.allInsects.allNames[action.payload].split('_')[0],
            order: state.allInsects.allOrders[action.payload],
            species: state.allInsects.allNames[action.payload].split('_')[1],
          },
          tree: {
            hosts: state.allInsects.allTreeHosts[action.payload],
          },
          allInsects: {
            ...state.allInsects,
            activeTab: action.payload,
          },
        };
      }

    case ActionTypes.SET_ALL_INSECTS:
      return {
        ...state,
        allInsects: {
          ...state.allInsects,
          allNames: [...state.allInsects.allNames, action.payload.genusSpecies],
          allOrders: [...state.allInsects.allOrders, action.payload.order],
          allFamilies: [...state.allInsects.allFamilies, action.payload.family],
          allFeedingGuilds: [...state.allInsects.allFeedingGuilds, action.payload.feedingGuild],
          allClimates: [...state.allInsects.allClimates, action.payload.climate],
          allNativeRanges: [...state.allInsects.allNativeRanges, action.payload.ecozone],
          allTreeHosts: [...state.allInsects.allTreeHosts, action.payload.treeHosts],
        },
      };
    case ActionTypes.SET_ALL_USER_INFO:
      return { ...state, user: action.payload };
    case ActionTypes.SET_INSECT_CLIMATE:
      return {
        ...state,
        insect: {
          ...state.insect,
          climate: {
            ...state.insect.climate,
            [action.payload.climate.toLowerCase()]: action.payload.checked ? 1 : 0,
          },
        },
      };
    case ActionTypes.SET_INSECT_ECOZONE:
      return {
        ...state,
        insect: {
          ...state.insect,
          ecozone: {
            ...state.insect.ecozone,
            [action.payload.ecozone.toLowerCase()]: action.payload.checked ? 1 : 0,
          },
        },
      }; case ActionTypes.SET_INSECT_FAMILY:
      return { ...state, insect: { ...state.insect, family: action.payload } };
    case ActionTypes.SET_INSECT_FEEDING_GUILD:
      return { ...state, insect: { ...state.insect, feedingGuild: action.payload } };
    case ActionTypes.SET_INSECT_GENUS:
      return { ...state, insect: { ...state.insect, genus: action.payload } };
    case ActionTypes.SET_INSECT_ORDER:
      return { ...state, insect: { ...state.insect, order: action.payload } };
    case ActionTypes.SET_INSECT_SPECIES:
      return { ...state, insect: { ...state.insect, species: action.payload } };
    case ActionTypes.SET_USER_EMAIL:
      return { ...state, user: { ...state.user, email: action.payload } };
    case ActionTypes.SET_USER_EMPLOYER:
      return { ...state, user: { ...state.user, employer: action.payload } };
    case ActionTypes.SET_USER_NAME:
      return { ...state, user: { ...state.user, name: action.payload } };
    case ActionTypes.SET_USER_OCCUPATION:
      return { ...state, user: { ...state.user, occupation: action.payload } };
    default:
      return state;
  }
};

export default FormReducer;
