import { adminService } from '../../services';
import ActionTypes from './types';
import { isValidEmail } from '../../utils';

/**
 * @description action creator for logging in user
 * @param {String} username username to log in
 * @param {String} password password to use
 */
export const loginUser = (username, password) => async (dispatch) => {
  if (!username || !password) {
    let text;
    if (!username && !password) {
      text = 'Missing username and password';
    } else if (!username) {
      text = 'Missing username';
    } else {
      text = 'Missing password';
    }
    dispatch({
      type: ActionTypes.SET_ADMIN_LOGIN_ERROR,
      payload: {
        badUsername: !username,
        badPassword: !password,
        text,
      },
    });
  } else {
    try {
      await adminService.login(username, password);
      const data = await adminService.getUserInfo(username);
      dispatch({ type: ActionTypes.SET_ADMIN_USER_INFO, payload: data });
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      dispatch({
        type: ActionTypes.SET_ADMIN_LOGIN_ERROR,
        payload: {
          error,
          badUsername: true,
          badPassword: true,
          text: errorMessage || 'Error: failed to log in',
        },
      });
    }
  }
};

/**
 * @description action creator for logging user out
 */
export const logoutUser = () => (dispatch) => {
  adminService.logout();
  dispatch({ type: ActionTypes.CLEAR_ADMIN_DATA });
};

/**
 * @description action creator for clearing errors
 */
export const clearAdminErrors = () => (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_ADMIN_ERRORS });
};

/**
 * @description action creator for getting information of logged in user
 * @param {String} username username
 */
export const getAdminUserInfo = username => async (dispatch) => {
  try {
    const data = await adminService.getUserInfo(username);
    dispatch({ type: ActionTypes.SET_ADMIN_USER_INFO, payload: data });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    dispatch({
      type: ActionTypes.SET_ADMIN_ERROR,
      payload: {
        error,
        text: errorMessage || 'Error: Failed to fetch admin user information',
      },
    });
  }
};

/**
 * @description action creator for getting information on all admin users
 */
export const getAllAdminUsers = async (dispatch) => {
  try {
    const data = await adminService.getAllAdminUsers();
    dispatch({ type: ActionTypes.SET_ALL_ADMIN_USERS, payload: data });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    dispatch({
      type: ActionTypes.SET_ADMIN_ERROR,
      payload: {
        error,
        text: errorMessage || 'Error: Failed to fetch all admin user information',
      },
    });
  }
};

/**
 * @description action creator for registering new user
 * @param {String} username username to create
 * @param {String} password password to create
 * @param {String} firstName first name to set
 * @param {String} lastName last name to set
 * @param {String} email email to set
 */
export const registerAdminUser = (username, password, firstName, lastName, email) => async (dispatch) => {
  const missingFields = [];
  const valid = isValidEmail(email);
  let text = '';
  if (!username) {
    missingFields.push('username');
  } if (!password) {
    missingFields.push('password');
  } if (!firstName) {
    missingFields.push('first name');
  } if (!lastName) {
    missingFields.push('last name');
  } if (!email) {
    missingFields.push('email');
  } else if (!valid) {
    text += 'invalid email. ';
  }

  if (missingFields.length > 0 || !valid) {
    if (missingFields.length > 0) {
      text += `missing ${missingFields.join(', ')}.`;
    }
    dispatch({
      type: ActionTypes.SET_ADMIN_ADD_ERROR,
      payload: {
        badUsername: !username,
        badPassword: !password,
        badFirstName: !firstName,
        badLastName: !lastName,
        badEmail: (!email || !isValidEmail(email)),
        text,
      },
    });
  } else {
    try {
      await adminService.registerAdminUser(username, password, firstName, lastName, email);
      dispatch({
        type: ActionTypes.SET_ADMIN_ADD_SUCCESS,
        payload: {
          text: `Added new admin: ${username}`,
        },
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      dispatch({
        type: ActionTypes.SET_ADMIN_ADD_ERROR,
        payload: {
          error,
          badUsername: true,
          badPassword: true,
          badFirstName: true,
          badLastName: true,
          badEmail: true,
          text: errorMessage || 'Error: Failed to add an admin',
        },
      });
    }
    try {
      const data = await adminService.getAllAdminUsers();
      dispatch({ type: ActionTypes.SET_ALL_ADMIN_USERS, payload: data });
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      dispatch({
        type: ActionTypes.SET_ADMIN_ERROR,
        payload: {
          error,
          text: errorMessage || 'Error: Failed to fetch all admin user information',
        },
      });
    }
  }
};

/**
 * @description action creator for resetting user password
 * @param {String} email email of user to reset password of and send new pass to
 */
export const resetPassword = email => async (dispatch) => {
  if (!email || !isValidEmail(email)) {
    dispatch({
      type: ActionTypes.SET_RESET_PASSWORD_ERROR,
      payload: {
        text: email ? 'Invalid email' : 'Missing email',
      },
    });
  } else {
    try {
      await adminService.resetPassword(email);
      // dispatch new action for reset success
      dispatch({
        type: ActionTypes.SET_RESET_PASSWORD_SUCCESS,
        payload: {
          text: 'An email has been sent to the address provided above with a link to reset your password!',
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_RESET_PASSWORD_ERROR,
        payload: {
          error,
          text: 'Your request could not be completed at this time.',
        },
      });
    }
  }
};

/**
 * @description action creator for deleting an existing user
 * @param {String} username username to create admin of
 */
export const deleteAdminUser = username => async (dispatch) => {
  try {
    await adminService.deleteAdminUser(username);
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    dispatch({
      type: ActionTypes.SET_ADMIN_ERROR,
      payload: {
        error,
        text: errorMessage || 'Error: Failed to delete admin',
      },
    });
  }
  try {
    const data = await adminService.getAllAdminUsers();
    dispatch({ type: ActionTypes.SET_ALL_ADMIN_USERS, payload: data });
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    dispatch({
      type: ActionTypes.SET_ADMIN_ERROR,
      payload: {
        error,
        text: errorMessage || 'Error: Failed to fetch all admin user information',
      },
    });
  }
};

/**
 * @description action creator for editing user info
 * @param {Object} fields contains
 * {String} first_name first name to replace
 * {String} last_name last name to replace
 * {String} email email to replace
 * {String} password password to replace
 */
export const editAdminUser = fields => async (dispatch) => {
  let badFirstName = false;
  let badLastName = false;
  let badEmail = false;
  let badPassword = false;
  let text = '';
  const missingFields = [];

  for (const [k, v] of Object.entries(fields)) {
    switch (k) {
      case 'password':
        if (v === '') {
          badPassword = true;
          missingFields.push('password');
        }
        break;
      case 'email':
        if (v === '') {
          badEmail = true;
          missingFields.push('email');
        } else if (!isValidEmail(v)) {
          badEmail = true;
          text = 'Invalid email. ';
        }
        break;
      case 'first_name':
        if (v === '') {
          badFirstName = true;
          missingFields.push('first name');
        }
        break;
      case 'last_name':
        if (v === '') {
          badLastName = true;
          missingFields.push('last name');
        }
        break;
      default:
    }
  }
  if (missingFields.length > 0) {
    text += `Missing ${missingFields.join(', ')}.`;
  }
  if (text.length > 0) {
    dispatch({
      type: ActionTypes.SET_ADMIN_EDIT_ERROR,
      payload: {
        badPassword,
        badFirstName,
        badLastName,
        badEmail,
        text,
      },
    });
  } else {
    try {
      await adminService.editAdminUser(fields);
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      dispatch({
        type: ActionTypes.SET_ADMIN_EDIT_ERROR,
        payload: {
          badPassword: true,
          badFirstName: true,
          badLastName: true,
          badEmail: true,
          error,
          text: errorMessage || 'Error: Failed to change admin info',
        },
      });
    }
    try {
      const data = await adminService.getAllAdminUsers();
      dispatch({ type: ActionTypes.SET_ALL_ADMIN_USERS, payload: data });
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      dispatch({
        type: ActionTypes.SET_ADMIN_ERROR,
        payload: {
          error,
          text: errorMessage || 'Error: Failed to fetch all admin user information',
        },
      });
    }
  }
};

/**
 * @description action creator for downloading output data as an admin
 */
export const downloadOutputData = (fileName, month, year) => async (dispatch) => {
  try {
    await adminService.downloadOutputData(fileName, month, year);
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    dispatch({
      type: ActionTypes.SET_ADMIN_DOWNLOAD_ERROR,
      payload: {
        error,
        text: errorMessage || 'Error: Failed to download output data',
      },
    });
  }
};
