import { connect } from 'react-redux';

import {
  setActiveTab,
} from '../../../../state/actions';

import ModelOutput from './component';

const mapStateToProps = (state) => {
  const {
    predictions: {
      isModelRunning,
      results,
      updates,
    },
    form: {
      allInsects: {
        activeTab,
        allNames,
        allOrders,
        allFamilies,
        allTreeHosts,
        allNativeRanges,
      },
    },

  } = state;

  return {
    activeTab,
    isModelRunning,
    predictionUpdates: updates,
    predictionResults: results,
    allInsectNames: allNames,
    allInsectOrders: allOrders,
    allInsectFamilies: allFamilies,
    allInsectTreeHosts: allTreeHosts,
    allInsectNativeRanges: allNativeRanges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTab) => {
      dispatch(setActiveTab(activeTab));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModelOutput);
