import { ActionTypes } from '../actions';

const initialState = {
  optionsError: {
    error: [],
    text: [],
  },
};

const ErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_OPTIONS_ERROR:
      return {
        ...state,
        optionsError: {
          error: [...state.optionsError.error, action.payload.error],
          text: [...state.optionsError.text, action.payload.text],
        },
      };
    case ActionTypes.CLEAR_OPTIONS_ERROR:
      return { ...state, optionsError: initialState.optionsError };
    default:
      return state;
  }
};

export default ErrorReducer;
