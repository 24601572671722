import React, { useState } from 'react';
import './style.scss';

import { Radio } from 'semantic-ui-react';

const CompositeModel = (props) => {
  const {
    graphs,
    insectProperty,
    modelNumber,
    modelText1,
    modelText2,
    naTreeProperty,
    radioButtons,
  } = props;

  const [radioClicked, setRadioClicked] = useState(2);
  const radioLabels = ['Insect Traits', 'Tree Traits', 'Tree Relatedness', 'Insect Relatedness'];
  const radioColors = ['#FCC9C9', '#FFF3D6', '#E7EEDB', '#D5DEEE'];

  return (
    <div className="composite-model-card">
      <div className="composite-text-container">
        <p className="composite-title">Composite Model {modelNumber}</p>
        <p className="composite-condition-label">For the following conditions:</p>
        <div className="composite-condition-container">
          <p>North American tree is a</p>
          <ul>
            <li>{naTreeProperty}</li>
          </ul>
          <p>Non-native insect has/is a</p>
          <ul>
            {insectProperty.map((p) => {
              return <li>{p}</li>;
            })}
          </ul>
        </div>
        <p className="composite-text">{modelText1}</p>
        <p className="composite-text">{modelText2}</p>
      </div>
      <div className="composite-graph-container">
        <div className="radio-buttons-container">
          {radioButtons.map((button, i) => {
            if (button) {
              return (
                <Radio
                  label={radioLabels[i]}
                  checked={radioClicked === i}
                  onChange={() => setRadioClicked(i)}
                  className="composite-radio"
                  style={radioClicked === i
                    ? { background: `${radioColors[i]}` }
                    : null}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
        <img src={graphs[radioClicked]} alt="diagram for this submodel" />

      </div>
    </div>
  );
};

export default CompositeModel;
