import React, { useEffect, useState } from 'react';
import excelLogo from '../../../../assets/icons/excel.svg';
import { Card, MonthSelect, YearSelect } from '../../components';

const downloadFileName = 'predictions';
const downloadFileExtension = 'csv';
const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

/**
 * @description React component for the Download Data card of the Admin Portal screen
 * @param {(string fileName, int month, int year) -> void} download function for downloading data csv with month and year query params
 * @param {Object} error represents downloadOutputDataError state variable
 * @param {() => any} onChange function called upon state variable changes
 */
const DownloadData = ({
  download = () => {},
  error,
  onChange = () => {},
}) => {
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  // calls onChange prop whenever a state variable is changed
  useEffect(onChange, [month, year]);

  // returns the filename based on selected month/year and current month/year
  function getFileName() {
    let name = `${downloadFileName}`;

    // does not specify a lower boundary month/year if either aren't seleceted
    // or if a future month is selected during the current year
    if (month > 0 && year > 0 && (year !== currentYear || month <= currentMonth)) {
      name += `_${month}/${year.toString().substr(-2)}`;
    }
    name += `.${downloadFileExtension}`;
    return name;
  }

  // calls download function with selected month depending on conditions
  function downloadWrapper() {
    // check for future month selection during current year
    if (month > 0 && year > 0 && (year !== currentYear || month <= currentMonth)) {
      download(getFileName(), month, year);
    } else {
      download(getFileName(), currentMonth, currentYear);
    }
  }

  return (
    <Card name="Download Data" className="fit-card">
      <div className="date-select-container">
        <MonthSelect className="month-select simple-dropdown" setMonth={setMonth} />
        <YearSelect className="year-select simple-dropdown" setYear={setYear} />
      </div>
      <p className="normal-text" style={{ width: '200px', alignSelf: 'center' }}>
        specify month and year to download data from that month
      </p>
      <div className="file-container">
        <img src={excelLogo} alt="Microsoft Excel logo" width="41" height="45" />
        <p className="file-name"> {getFileName()} </p>
      </div>
      {error ? <p id="error-message" className="error-text">{error.text}</p> : null}
      <button
        type="button"
        className="primary-button"
        onClick={downloadWrapper}
        style={{ marginTop: '29px' }}
      >
        Download
      </button>
    </Card>
  );
};

export default DownloadData;
