import axios from 'axios';
import { getLocal, setLocal, removeLocal } from '../utils';
import { LOCAL_STORAGE_KEYS } from '../constants';

/**
 * @description fetch all admin users
 * @return {Object[]} all admin users in database
 */
export const getAllAdminUsers = async () => {
  const url = `${global.API_URL}/admin`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);

  const { data: { data } } = await axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data;
};

/**
 * @description fetch info on logged in user
 * @return {Object} logged in user information
 */
export const getUserInfo = async (username) => {
  const url = `${global.API_URL}/admin/${username}`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);

  const { data: { data } } = await axios.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data[0];
};

/**
 * @description log admin user in
 * @return {Object} token for user
 */
export const login = async (username, password) => {
  const url = `${global.API_URL}/admin/login`;

  const { data: { data } } = await axios.get(url, {
    auth: {
      username,
      password,
    },
  });

  setLocal(LOCAL_STORAGE_KEYS.admin.token, data.token);
  setLocal(LOCAL_STORAGE_KEYS.admin.username, data.username);

  return data;
};

/**
 * @description logout admin user
 */
export const logout = () => {
  removeLocal(LOCAL_STORAGE_KEYS.admin.token);
  removeLocal(LOCAL_STORAGE_KEYS.admin.username);
};

/**
 * @description creates an admin user with the specified information
 * @param {String} username username
 * @param {String} password password
 * @param {String} firstName first name
 * @param {String} lastName last name
 * @param {String} email email
 */
export const registerAdminUser = async (username, password, firstName, lastName, email) => {
  const url = `${global.API_URL}/admin/register`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);
  const { data: { data } } = await axios.post(url, {
    username,
    password,
    first_name: firstName,
    last_name: lastName,
    email,
  }, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data[0];
};

/**
 * @description resets admin password with the specified email
 * @param {String} email email
 */
export const resetPassword = async (email) => {
  const url = `${global.API_URL}/admin/reset-password/${email}`;
  const { data: { data } } = await axios.put(url);

  return data[0];
};

/**
 * @description deletes an admin user with the specified username
 * @param {String} username username
 */
export const deleteAdminUser = async (username) => {
  const url = `${global.API_URL}/admin/${username}`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);
  const { data } = await axios.delete(url, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data;
};

/**
 * @description changes admin user info with the specified information
 * @param {Object} fields contains
 * {String} first_name first name to replace
 * {String} last_name last name to replace
 * {String} email email to replace
 * {String} password password to replace
 */
export const editAdminUser = async (fields) => {
  const username = getLocal(LOCAL_STORAGE_KEYS.admin.username);
  const url = `${global.API_URL}/admin/${username}`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);

  const { data: { data } } = await axios.put(url, fields, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data[0];
};

/**
 * @description download output data as a csv for an admin
 * @param {String} fileName filename
 * @param {int} month month
 * @param {int} email year
 */
export const downloadOutputData = async (fileName, month, year) => {
  // generate url
  const query = (month > 0 && year > 0) ? `?month=${month}&year=${year}` : '';
  const url = `${global.API_URL}/predictions/download${query}`;
  const token = getLocal(LOCAL_STORAGE_KEYS.admin.token);

  // download blob and create object url
  const { data } = await axios.get(url, {
    responseType: 'blob',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const objectUrl = URL.createObjectURL(data);

  // generate link for browser to click (allows us to set the name of the file)
  const link = document.createElement('a');
  link.href = objectUrl;
  link.setAttribute('download', `${fileName}`);

  // trigger download then remove from DOM
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * @description runs the model with the information given from csv
 * @param {Object} fields
 */
export const batchModelRun = async (fields) => {
  const url = `${global.API_URL}/data-model`;
  return axios.post(url, fields);
};
