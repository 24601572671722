/* eslint-disable import/prefer-default-export */
import ActionTypes from './types';

/**
 * @description action creator setting websocket connection
 * @param {Object} socket websocket connection
 */
export const setSocket = (socket) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_SOCKET,
      payload: socket,
    });
  };
};
