import React, { useEffect, useState } from 'react';
import { Card } from '../../components';

/**
 * @description React component for the Add Site Administrator card of the Admin Portal screen
 * @param {Object} error represents downloadOutputDataError state variable
 * @param {() => any} onChange function called upon state variable changes
 * @param {(string username, string firstName, string lastName, string email, string password) => any} submit takes state variables, called when button is pressed
 * @param {Object} success represents success state variable
 */
const AddAdmin = ({
  error,
  onChange = () => {},
  submit = () => {},
  success,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // clears state vars whenever the success prop is changed
  useEffect(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setUsername('');
    setPassword('');
  }, [success]);

  // called by button press
  const onClick = () => submit(username, password, firstName, lastName, email);

  // wrappers for state var set functions to call onChange()
  function setFirstNameWrapper(newFirstName) {
    setFirstName(newFirstName);
    onChange();
  }

  function setLastNameWrapper(newLastName) {
    setLastName(newLastName);
    onChange();
  }

  function setEmailWrapper(newEmail) {
    setEmail(newEmail);
    onChange();
  }

  function setUsernameWrapper(newUsername) {
    setUsername(newUsername);
    onChange();
  }

  function setPasswordWrapper(newPassword) {
    setPassword(newPassword);
    onChange();
  }

  return (
    <Card name="Add Site Administrator" className="fit-card">
      <form>
        <label htmlFor="addFirstName">
          First Name
          <input
            className={`text-box ${error?.badFirstName ? 'text-box-error' : ''}`}
            id="addFirstName"
            name="given-name"
            autoComplete="given-name"
            value={firstName}
            onChange={e => setFirstNameWrapper(e.target.value)}
          />
        </label>
        <label htmlFor="addLastName">
          Last Name
          <input
            className={`text-box ${error?.badLastName ? 'text-box-error' : ''}`}
            id="addLastName"
            name="family-name"
            autoComplete="family-name"
            value={lastName}
            onChange={e => setLastNameWrapper(e.target.value)}
          />
        </label>
        <label htmlFor="addEmail">
          Email
          <input
            className={`text-box ${error?.badEmail ? 'text-box-error' : ''}`}
            id="addEmail"
            name="email"
            autoComplete="email"
            value={email}
            onChange={e => setEmailWrapper(e.target.value)}
          />
        </label>
        <label htmlFor="addUsername">
          Username
          <input
            className={`text-box ${error?.badUsername ? 'text-box-error' : ''}`}
            id="addUsername"
            name="username"
            autoComplete="username"
            value={username}
            onChange={e => setUsernameWrapper(e.target.value)}
          />
        </label>
        <label htmlFor="addPassword">
          Password
          <input
            className={`text-box ${error?.badPassword ? 'text-box-error' : ''}`}
            id="addPassword"
            name="new-password"
            autoComplete="new-password"
            value={password}
            onChange={e => setPasswordWrapper(e.target.value)}
          />
        </label>
        <div className="bottom-flex-container">
          <button
            type="button"
            className="primary-button"
            onClick={onClick}
            style={{ marginTop: '36px' }}
          >
            Add admin
          </button>
          {error ? <p id="error-message" className="error-text">{error.text}</p> : null}
          {success ? <p id="success-message" className="normal-text">{success.text}</p> : null}
        </div>
      </form>
    </Card>
  );
};

export default AddAdmin;
