import { connect } from 'react-redux';

import {
  clearPredictionUpdates,
  fetchPredictionResults,
  setActiveTab,
  setAllInsects,
} from '../../state/actions';

import DataEntry from './component';

const mapStateToProps = (state) => {
  const {
    predictions: {
      isModelRunning,
      results,
      updates,
    },
    form: {
      insect: {
        climate,
        ecozone,
        family,
        feedingGuild,
        genus,
        order,
        species,
      },
      allInsects: {
        allNames,
      },
      tree: {
        hosts,
      },
    },

  } = state;

  return {
    allInsectNames: allNames,
    hosts,
    insectClimate: climate,
    insectEcozone: ecozone,
    insectFamily: family,
    insectFeedingGuild: feedingGuild,
    insectGenus: genus,
    insectOrder: order,
    insectSpecies: species,
    insectTreeHosts: hosts,
    isModelRunning,
    predictionUpdates: updates,
    predictionResults: results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearPredictionUpdates: () => {
      dispatch(clearPredictionUpdates);
    },
    fetchPredictionResults: () => {
      dispatch(fetchPredictionResults);
    },
    setActiveTab: (activeTab) => {
      dispatch(setActiveTab(activeTab));
    },
    setAllInsects: (order, family, genusSpecies, feedingGuild, climate, ecozone, treeHosts) => {
      dispatch(setAllInsects(order, family, genusSpecies, feedingGuild, climate, ecozone, treeHosts));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataEntry);
