import React from 'react';
import { HeaderMenu, FooterMenu } from '../../components';
import './style.scss';

const Team = (_props) => {
  return (
    <div>
      <HeaderMenu />
      <div className="team-root">
        <div className="team-content-container">
          <h1 className="team-title">Supporters</h1>
          <div className="team-flex-container">
            <div className="team-left-column">
              <div className="team-textbox">
                <h2 className="team-subtitle">People</h2>
                <ul className="team-text">
                  <li> Angela M. Mech, University of Maine, School of Biology and Ecology </li>
                  <li>
                    Ashley N. Schulz, Colorado State University, Department of Agricultural Biology
                    and Mississippi State University, Department of Forestry
                  </li>
                  <li> Matthew P. Ayres, Dartmouth College, Department of Biological Sciences </li>
                  <li>
                    Kamal J.K. Gandhi, The University of Georgia, Daniel B. Warnell School of
                    Forestry and Natural Resources
                  </li>
                  <li>
                    Ruth A. Hufbauer, Colorado State University, Department of Agricultural Biology
                    and Graduate Degree Program in Ecology
                  </li>
                  <li> Travis D. Marsico, Arkansas State University, Department of Biological Sciences </li>
                  <li> Kenneth F. Raffa, University of Wisconsin, Department of Entomology </li>
                  <li> Patrick C. Tobin, University of Washington, School of Environmental and Forest Sciences </li>
                  <li>
                    Daniel R. Uden, Center for Resilience in Agricultural Working Landscapes, School
                    of Natural Resources, Department of Agronomy and Horticulture, University of
                    Nebraska‐Lincoln
                  </li>
                  <li> Jessen T. Havill, Denison University, Department of Computer Science</li>
                  <li> Carissa F. Aoki, Bates College </li>
                  <li> Nathan P. Havill, USDA Forest Service, Northern Research Station </li>
                  <li>
                    Andrew M. Liebhold, USDA Forest Service, Northern Research Station and Czech
                    University of Life Sciences, Faculty of Forestry and Wood Sciences
                  </li>
                  <li> Angela M. Hoover, U.S. Geological Survey, Southwest Biological Science Center </li>
                  <li> Kathryn A. Thomas, U.S. Geological Survey, Southwest Biological Science Center </li>
                  <li> Daniel A. Herms, The Davey Tree Expert Company </li>
                  <li> Scott Maco, The Davey Tree Expert Company </li>
                  <li> Mike Binkley, The Davey Tree Expert Company </li>
                  <li> Kevin Whalen, The Davey Tree Expert Company </li>
                </ul>
              </div>
              <div className="team-textbox">
                <h2 className="team-subtitle">Project Development</h2>
                <p className="team-text">
                  Web design and development by the Dartmouth Applied Learning and Innovation (DALI)
                  Lab at Dartmouth College: Jenny Song, Team Manager; Hannah Utter, Team Manager;
                  Sonal Butala, Team Manager; Jessica Cheng, Developer; Thomas Monfre, Developer;
                  Pape Sow Traoré, Developer; Angela Zhang, Developer; Andrew Chen, Developer;
                  Tanli Su, Developer; Jeanne Annpark, Designer; Bridget Ma, Designer; Tony Perez,
                  Designer; Eric Wang, Designer; Rahul Rao, Designer.
                  <br /><br />
                  i-Tree Pest Predictor was developed by Davey Tree Expert Company in partnership
                  with DALI Lab at Dartmouth College.
                  <br /><br />
                  i-Tree<sup>®</sup> is a registered trademark.
                </p>
              </div>
            </div>
            <div className="team-right-column">
              <div className="team-textbox">
                <h2 className="team-subtitle">Support</h2>
                <p className="team-text">
                  The production of this resource was made possible by a grant from the USDA Forest
                  Service National Urban and Community Forestry Advisory Council Grant
                  (Grant No. 19-DG-11132544-022).<br /><br />
                  This project was conducted as a part of the “Predicting the next high-impact
                  insect invasion: elucidating traits and factors determining the risk of introduced
                  herbivorous insects on North American native plants” working group supported by
                  the United States Geological Survey John Wesley Powell Center for Analysis and
                  Synthesis (No. G16AC00065), and the USDA Forest Service Eastern Forest
                  Environmental Threat Assessment (Grant No. 15‐JV‐11242303‐103).<br /><br />
                  Additional support was provided by the University of Washington, Nebraska
                  Cooperative Fish and Wildlife Research Unit, National Science Foundation Long Term
                  Ecological Research program, USDA Forest Service International Programs, USDA
                  National Institute of Food and Agriculture (Hatch projects 1012868 and 022124), US
                  Geological Survey Ecosystems Mission Area, and the David R.M. Scott Endowed
                  Professorship in Forest Resources, University of Washington.<br /><br />
                  The list of woody plants utilized by this resource was provided by: Hoffman,
                  M.H.A., 2021: List of names of woody plants: International Standard ENA Applied
                  Plant Research. Prak-tijkonderzoek Plant & Omgeving.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    </div>
  );
};

export default Team;
