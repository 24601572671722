const OCCUPATIONS = [
  'citizen',
  'extension specialist',
  'forester',
  'research scientist-higher education',
  'research scientist-government/non-profit',
  'student',
  'tree professional',
  'other government agency employee',
  'other',
];

const CLIMATES = [
  'Tropical',
  'Dry',
  'Temperate',
  'Continental',
];

const ECOZONES = [
  'Afrotropical',
  'Autralasian',
  'Indomalayan',
  'Neotropical',
  'Palearctic_Asia',
  'Palearctic_Europe',
];

const CLIMATES_OBJECT = {
  tropical: 0,
  dry: 0,
  temperate: 0,
  continental: 0,
};

const ECOZONES_OBJECT = {
  afrotropical: 0,
  autralasian: 0,
  indomalayan: 0,
  neotropical: 0,
  palearctic_asia: 0,
  palearctic_europe: 0,
};

const OPTIONS = {
  OCCUPATIONS,
  CLIMATES,
  ECOZONES,
  CLIMATES_OBJECT,
  ECOZONES_OBJECT,
};

export default OPTIONS;
