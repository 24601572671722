import React, { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { OPTIONS } from '../../../../constants';

import './style.scss';

const BasicInfo = (props) => {
  const {
    modalVisible,
    user,
    setAllUserInfo,
    setModalVisible,
    setUserEmail,
    setUserEmployer,
    setUserName,
    setUserOccupation,
  } = props;

  const occupationDropdownOpts = OPTIONS.OCCUPATIONS.map((op) => {
    return { key: op, value: op, text: op };
  });

  useEffect(() => {
    if (!modalVisible) {
      setAllUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    }
  }, []);

  return (
    <div className="modal-container">
      <p className="bold">Before using i-Tree Pest Predictor</p>
      <p>
        Please, enter some information about yourself that will help us understand who is using the tool and how they are utilizing the outputs.
        We only require information about occupation, but would appreciate if you would enter more information about yourself. Thank you!
      </p>
      <p className="red">* indicates required</p>
      <div className="modal-user-input">
        <label htmlFor="name">
          Name
          <input id="name" onChange={event => setUserName(event.target.value)} />
        </label>
      </div>
      <div className="modal-user-input">
        <label htmlFor="employer">
          Employer
          <input id="employer" onChange={event => setUserEmployer(event.target.value)} />
        </label>
      </div>
      <div className="modal-user-input">
        <label htmlFor="email-address">
          Email Address
          <input id="email-address" onChange={event => setUserEmail(event.target.value)} />
        </label>
      </div>
      <div className="modal-user-input">
        <p className="required-input-label">Occupation<span className="red">*</span></p>
        <Dropdown
          className="occupation-dropdown"
          placeholder="- Select -"
          fluid
          selection
          options={occupationDropdownOpts}
          onChange={(event, selected) => { setUserOccupation(selected.value); }}
        />
      </div>
      <button
        disabled={!user?.occupation}
        className="continue-button button"
        type="button"
        onClick={() => {
          setModalVisible(false);
          localStorage.setItem('modalClose', true);
          localStorage.setItem('userInfo', JSON.stringify(user));
        }}
      >Continue to Tool
      </button>
    </div>
  );
};

export default BasicInfo;
