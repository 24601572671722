import { connect } from 'react-redux';

import OutputTable from './component';

const mapStateToProps = (state) => {
  const {
    predictions: {
      results,
    },
    form: {
      allInsects: {
        activeTab,
      },
    },
  } = state;

  return {
    activeTab,
    predictionResults: results,
  };
};

export default connect(
  mapStateToProps,
)(OutputTable);
