/* eslint-disable import/prefer-default-export */
import ActionTypes from './types';
import { SocketActions } from '../../constants';

/**
 * @description action creator for clearing model's updates
 */
export const clearPredictionUpdates = (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_PREDICTION_UPDATES, payload: [] });
};

/**
 * @description action creator to fetch pr by sending form inputs
 */
export const fetchPredictionResults = async (dispatch, getState) => {
  try {
    const {
      app: {
        socket,
      },
      form: modelInputs,
    } = getState();

    socket.emit(SocketActions.REQUEST_MODEL_RUN, modelInputs);
    dispatch({ type: ActionTypes.SET_MODEL_RUNNING, payload: true });

    socket.on(SocketActions.MODEL_STATUS_UPDATE, (data) => {
      dispatch({ type: ActionTypes.FETCH_PREDICTION_UPDATE, payload: data });
    });

    socket.on(SocketActions.MODEL_COMPLETED, (data) => {
      dispatch({ type: ActionTypes.FETCH_PREDICTION_RESULTS, payload: data });
      dispatch({ type: ActionTypes.SET_MODEL_RUNNING, payload: false });
    });
  } catch (error) {
    // TODO: Handle error
    console.log(error);
  }
};
