import React from 'react';
import './style.scss';

import usfsLogo from '../../assets/icons/forest-service.png';
import daveyLogo from '../../assets/icons/davey-tree.png';
import arborLogo from '../../assets/icons/arbor-day-foundation.png';
import smaLogo from '../../assets/icons/society-of-municipal-arborist.png';
import isaLogo from '../../assets/icons/international-society-of-arboriculture.png';
import caseyLogo from '../../assets/icons/casey-trees.png';
import esfLogo from '../../assets/icons/environmental-science-and-forestry.png';
import daliLogo from '../../assets/icons/dali_blue.png';

/**
 * @description footer supporters menu found below home and team pages lists out supporting organizations with links
 */
const FooterMenu = (__props) => {
  return (
    <div className="footer-root">
      <div className="footer-row">
        <a href="https://www.fs.usda.gov/">
          <img className="footer-item" src={usfsLogo} alt="US Forest Service" />
        </a>
        <a href="https://www.davey.com/">
          <img className="footer-item" src={daveyLogo} alt="Davey Tree" />
        </a>
        <a href="https://www.arborday.org/">
          <img className="footer-item" src={arborLogo} alt="Arbor Day Foundation" />
        </a>
        <a href="https://www.urban-forestry.com/">
          <img className="footer-item" src={smaLogo} alt="Society of Municipal Arborists" />
        </a>
        <a href="https://www.isa-arbor.com/">
          <img className="footer-item" src={isaLogo} alt="International Society of Arboriculture" />
        </a>
        <a href="https://caseytrees.org/">
          <img className="footer-item" src={caseyLogo} alt="Casey Trees" />
        </a>
        <a href="https://www.esf.edu/">
          <img className="footer-item" src={esfLogo} alt="SUNY College of Environmental Science and Forestry" />
        </a>
      </div>
      <hr />
      <div className="footer-row">
        <p className="footer-text">
          Designed and developed by <a href="https://dali.dartmouth.edu/"><img className="footer-dali-logo" src={daliLogo} alt="Dali Lab" /></a>
        </p>
      </div>
      <div className="footer-row">
        <p className="footer-text">
          Pest Predictor is proudly hosted by i-Tree<sup>®</sup> but i-Tree cannot provide user support for it.
          Please, contact <a
            href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#97;&#110;&#103;&#101;&#108;&#97;&#46;&#109;&#101;&#99;&#104;&#64;&#109;&#97;&#105;&#110;&#101;&#46;&#101;&#100;&#117;"
          >&#97;&#110;&#103;&#101;&#108;&#97;&#46;&#109;&#101;&#99;&#104;&#64;&#109;&#97;&#105;&#110;&#101;&#46;&#101;&#100;&#117;</a> for questions and comments.
        </p>
      </div>
      <div className="footer-row">
        <p className="footer-text">i-Tree is a registered trademark.</p>
      </div>
      <div className="footer-row">
        <p className="footer-text">
          Use of this tool indicates acceptance of the <a href="https://help.itreetools.org/eula/?embed=true">EULA</a>.
        </p>
      </div>
    </div>
  );
};

export default FooterMenu;
