import { connect } from 'react-redux';

import OutputHistogram from './component';

const mapStateToProps = (state) => {
  const {
    form: {
      allInsects: {
        activeTab,
        allNames,
        allOrders,
        allFamilies,
      },
    },
    predictions: {
      results,
    },
  } = state;

  return {
    activeTab,
    allNames,
    allOrders,
    allFamilies,
    predictionResults: results,
  };
};

export default connect(
  mapStateToProps,
)(OutputHistogram);
