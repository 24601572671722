import React, { useEffect, useState } from 'react';
import { Card } from '../../components';
import logo from '../../../../assets/icons/i-tree.svg';

/**
 * @description React component for the forgot password card of the Admin Portal screen
 * @param {Object} error represents error state variable
 * @param {() => any} onChange function called upon state variable changes
 * @param {() => any} onClickBack function called upon back button press
 * @param {(string email) => any} submit takes state variable, called when button is pressed
 * @param {Object} success represents success state variable
 */
const ForgotPassword = ({
  error,
  onChange = () => {},
  onClickBack = () => {},
  submit = () => {},
  success,
}) => {
  const [email, setEmail] = useState('');

  // calls onChange() prop whenever a state variable is changed
  useEffect(onChange, [email]);

  // called by Reset Password button press
  const onClickResetPassword = () => submit(email);

  return (
    <Card className="login-card">
      <img className="logo" src={logo} alt="i-Tree" />
      <div className="card-title-box">
        <h1 className="card-title">Forgot Password?</h1>
      </div>
      <form>
        <label htmlFor="resetPasswordEmail">
          Email
          <input
            className={`text-box ${error && !success ? 'text-box-error' : ''}`}
            id="resetPasswordEmail"
            name="email"
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </label>
        <div className="bottom-flex-container">
          <button type="button" className="text-button" onClick={onClickBack}>Back to Login</button>
          {success ? (
            <div className="forgot-password-text-container"><p id="success-message" className="normal-text">{success.text}</p></div>
          ) : (
            <button type="button" className="primary-button" onClick={onClickResetPassword}>Reset Password</button>
          )}
          {error ? <p id="error-message" className="error-text">{error.text}</p> : null}
        </div>
      </form>
    </Card>
  );
};

export default ForgotPassword;
