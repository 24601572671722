/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

import X from '../../../../assets/icons/x.svg';

import './style.scss';

const AutofillField = (props) => {
  const {
    addTreeHost,
    instruction,
    fetchTreeHosts,
    removeTreeHost,
    selectedHosts,
    treeGenera,
    treeHosts,
  } = props;

  const genusOptions = Object.values(treeGenera).map(genus => ({
    value: genus,
    label: genus,
  }));

  const speciesOptions = Object.values(treeHosts)
    .filter(hi => hi.split('_')[1] !== 'sp')
    .map(host => ({
      label: host.split('_').slice(1),
      value: host.split('_').slice(1),
    }));

  speciesOptions.unshift({
    label: 'sp',
    value: 'sp',
  });

  const [genusValue, setGenus] = useState('');
  const [speciesValue, setSpecies] = useState('');

  const clearInputs = () => {
    setGenus('');
    setSpecies('');
  };

  const handleGenusChange = (selectedGenus) => {
    setGenus(selectedGenus.value);
    fetchTreeHosts(selectedGenus.value);
  };

  const handleHostsChange = (selectedTrees) => {
    setSpecies(selectedTrees.value);
    const newTree = `${genusValue}_${selectedTrees.value}`;
    if (genusValue.length > 0 && selectedTrees.value.length > 0 && !selectedHosts.includes(newTree)) {
      addTreeHost(newTree);
    }
    clearInputs();
  };

  const dropdownStylesGenus = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#F2F2F2' : null,
      color: '#000000',
    }),
    control: base => ({
      ...base,
      fontFamily: 'Mulish',
      fontSize: '14px',
      background: '#F4F5F4',
      border: '1x solid',
      borderColor: '#A0A4A8',
      borderRadius: '5px 0px 0px 0px',
    }),
  };

  const dropdownStylesSpecies = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#F2F2F2' : null,
      color: '#000000',
    }),
    control: base => ({
      ...base,
      fontFamily: 'Mulish',
      fontSize: '14px',
      background: '#F4F5F4',
      border: '1x solid',
      borderColor: '#A0A4A8',
      borderRadius: '0px 5px 0px 0px',
    }),
  };

  const renderTrees = () => {
    let startLetter = '';
    let parity = 0;
    return (
      <div className="all-trees-container">
        {selectedHosts
          .sort((a, b) => a.localeCompare(b))
          .map((tree) => {
            parity += 1;
            if (tree.charAt(0) !== startLetter) {
              startLetter = tree.charAt(0);
              return (
                <div>
                  <p className="letter-line">{startLetter}</p>
                  <div className={`tree-line ${parity % 2 === 0 ? 'lighter' : 'darker'}`}>
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => removeTreeHost(tree)}
                    >
                      <img src={X} alt="x" />
                    </button>
                    <p>{tree.split('_').join(' ')}</p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={`tree-line ${parity % 2 === 0 ? 'lighter' : 'darker'}`}>
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => removeTreeHost(tree)}
                  >
                    <img src={X} alt="x" />
                  </button>
                  <p>{tree.split('_').join(' ')}</p>
                </div>

              );
            }
          })}
      </div>
    );
  };
  return (
    <div className="autofill-container">
      <a
        data-tip
        data-for="tree-autofill"
      >
        <div className="basic-user-input autofill-input-container">
          <Select
            onChange={handleGenusChange}
            options={genusOptions}
            placeholder="- Select or Start Typing -"
            value={genusValue === '' ? null : { label: genusValue, value: genusValue }}
            styles={dropdownStylesGenus}
            className="autofill-dropdown"
          />
          <Select
            onChange={handleHostsChange}
            options={speciesOptions}
            placeholder="- Select or Start Typing -"
            value={speciesValue === '' ? null : { label: speciesValue, value: speciesValue }}
            styles={dropdownStylesSpecies}
            className="autofill-dropdown"
          />
        </div>
      </a>
      {instruction ? (
        <ReactTooltip
          arrowColor="white"
          className="tooltips"
          border
          borderColor="#517ED0"
          effect="solid"
          id="tree-autofill"
          multiline
          place="right"
          textColor="#737B7D"
        >
          <p><b>Native Host Trees</b></p>
          <p>Please enter all native woody hosts (i.e., coevolved with the insect </p>
          <p>and shares the same native range). Do not include herbaceous hosts</p>
          <p>
            <b>
              Inaccurate or incomplete host tree lists will generate inaccurate risk predictions. Because of the importance of evolutionary
              history to plant-insect interactions, the relatedness of North American trees to native host trees is the single most important predictor of risk.
            </b>
          </p>
          <p>Leaving out known native host trees may lead to less accurate model predictions</p>
          <p>If there are more than 50 woody hosts associated with the insect, </p>
          <p>select 50 that encompass the taxonomic diversity of the insect&apos;s native host trees</p>
        </ReactTooltip>
      ) : null}

      {renderTrees()}
    </div>


  );
};


export default AutofillField;
