import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { ROUTES } from '../../constants';
import './style.scss';
import treeLogo from '../../assets/icons/i-tree.svg';

/**
 * @description header navigation menu found above every page except /predictions and /admin
 *              users of this component are responsible for allocating 86px of space at the
 *              top of the screen to be covered by the component
 */
const HeaderMenu = (props) => {
  // tracks the current page to determine which menu item to highlight
  const location = useLocation();
  return (
    <div className="header-root">
      <div className="header-flex">
        <a className="header-brand" href="https://www.itreetools.org/">
          <img className="header-icon" src={treeLogo} alt="i-Tree" />
          <div className="header-title-container">
            <h1 className="header-title">Pest Predictor</h1>
          </div>
        </a>
        <Link className={`header-item ${location.pathname === ROUTES.HOME ? 'header-item-selected' : ''}`} to={ROUTES.HOME}>Home</Link>
        <Link className={`header-item ${location.pathname === ROUTES.DATA_ENTRY ? 'header-item-selected' : ''}`} to={ROUTES.DATA_ENTRY}>Project</Link>
        <Dropdown className="header-item" text="Menu">
          <Dropdown.Menu className="header-dropdown">
            <Dropdown.Item onClick={() => props.history.push(ROUTES.SCIENCE)}>
              <p className={`header-dropdown-item ${location.pathname === ROUTES.SCIENCE ? 'header-item-selected' : ''}`}>Science</p>
            </Dropdown.Item>
            <hr />
            <Dropdown.Item onClick={() => props.history.push(ROUTES.PUBLICATIONS_AND_CODE)}>
              <p className={`header-dropdown-item ${location.pathname === ROUTES.PUBLICATIONS_AND_CODE ? 'header-item-selected' : ''}`}>Publications and Code</p>
            </Dropdown.Item>
            <hr />
            <Dropdown.Item onClick={() => props.history.push(ROUTES.TEAM)}>
              <p className={`header-dropdown-item ${location.pathname === ROUTES.TEAM ? 'header-item-selected' : ''}`}>Team</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default withRouter(HeaderMenu);
