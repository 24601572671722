import React from 'react';

/**
 * @description React table component helper for the Admin Portal Admin List container
 * @param {Object} data holds table row items and row id
 * @param {(string) => any} onClickButton function that is passed the item.username value of the row's item
 */
const TableRow = ({ data, onClickButton }) => {
  const {
    id,
    username,
    first_name: firstName,
    last_name: lastName,
    email,
  } = data;

  const formattedData = [{
    value: firstName,
    key: `${id}_firstName`,
  }, {
    value: lastName,
    key: `${id}_lastName`,
  }, {
    value: username,
    key: `${id}_username`,
  }, {
    value: email,
    key: `${id}_email`,
  }, {
    value: <input type="button" value="Delete Admin" className="left-text-button" onClick={() => onClickButton(username)} />,
    key: `${id}_button`,
  }];

  return (
    <tr>
      {formattedData.map((item) => {
        return <td key={item.key}>{item.value}</td>;
      })}
    </tr>
  );
};

export default TableRow;
