/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import Rodal from 'rodal';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { HeaderMenu, FooterMenu } from '../../components';
import metadataPdf from '../../assets/docs/i-Tree_Pest-Predictor_metadata.pdf';

import 'rodal/lib/rodal.css';

import {
  AutofillField,
  BasicInfo,
  DropDownItems,
  ModelOutput,
} from './components';

import './style.scss';

const DataEntry = (props) => {
  const [instruction, setInstruction] = useState(true);
  const [modalVisible, setModalVisible] = useState(!localStorage.getItem('modalClose'));

  const {
    hosts,
    insectClimate,
    insectEcozone,
    insectFamily,
    insectFeedingGuild,
    insectGenus,
    insectOrder,
    insectSpecies,
    insectTreeHosts,
    clearPredictionUpdates,
    fetchPredictionResults,
    predictionResults,
    setActiveTab,
    setAllInsects,
  } = props;

  const onFormSubmit = () => {
    clearPredictionUpdates();
    setAllInsects(insectOrder, insectFamily, `${insectGenus}_${insectSpecies}`, insectFeedingGuild, insectClimate, insectEcozone, insectTreeHosts);
    fetchPredictionResults();
    setActiveTab(predictionResults.length);
  };

  return (
    <div>
      <HeaderMenu />
      <div className="model-root">
        <Rodal
          animation="door"
          visible={modalVisible}
          showCloseButton={false}
          onClose={() => setModalVisible(false)}
          width={500}
          height={650}
        >
          <BasicInfo setModalVisible={setModalVisible} modalVisible={modalVisible} />
        </Rodal>
        <div className="model-content-container">
          <div className="model-text-container">
            <h1 className="model-title">i-Tree Pest Predictor Tool</h1>
            <p className="model-text">
              To generate predictions about the potential impact of a non-native insect, our models require a set of
              specific insect and native host tree data that are entered by the user. <Link className="model-link" to={ROUTES.SCIENCE}>Read the science behind the tool.</Link>
            </p>
            <p className="model-text">
              For information about what needs to be entered, visit
              the <Link className="model-link" to={ROUTES.RESOURCES}>resources</Link> page.
            </p>
            <p className="model-text">
              The required information includes the following:
            </p>
            <ul className="model-list">
              <li>taxonomic information for the insect (Order, Family, Genus, Species)</li>
              <li>the feeding guild of the insect (i.e., folivore, gall, reproductive, root, sap, wood)</li>
              <li>climate in the native range of the insect (i.e., Tropical, Dry, Temperate, Continental, Polar)</li>
              <li>native range of the insect (i.e., Afrotropical, Australasian, Indomalayan, Neotropical, Oceanian, Palearctic Asia, Palearctic Europe)</li>
              <li>the host trees of the insect in its native range (scientific name [Genus species]).</li>
            </ul>
            <p className="model-text">
              These data are then run against all possible North American trees relevant to the Insect
              (i.e., if the insect only feeds on conifers, predicted risks will only be calculated for
              North American conifers). The results are only as good as the data entered. Please try to
              be as thorough as possible.
            </p>
            <p className="model-text">
              For example, if an insect has a diverse range of tree hosts (&gt; 50 tree species), try to reflect that diversity in
              the species you enter, especially if you do not plan to enter all species. You may enter “sp” for the host
              tree species if you only know the genus, or if there are too many species to enter all of them. However,
              the model performs better with the species name information.
            </p>
            <p className="model-text">
              The metadata for model input and output is available in a <Link className="model-link" to={metadataPdf} target="_blank">PDF</Link>.
            </p>
          </div>
          <div className="container">
            <div className="inputs-container">
              <div className="inputs-half">
                <h1>Insect Information</h1>
                <div>
                  <input
                    id="checked"
                    checked={instruction}
                    type="checkbox"
                    className="checkbox"
                    onChange={() => setInstruction(!instruction)}
                  />
                  <label htmlFor="checked" id="checked-label" className="checkbox-label">
                    Automatically display tooltip for selected input
                  </label>
                </div>

                <DropDownItems showInstruction={instruction} />

                <h1>Native Host Trees</h1>
                <p>(i.e., host trees in the insect’s native range; please visit the <Link className="model-link" to={ROUTES.RESOURCES}>Resources page</Link> for assistance with identifying these)</p>
                <div className="autofill-instructions">
                  <p className="input-category-title"><b>Genus.</b> Select from list. Genera not in the list
                    are not in the phylogeny and cannot be used
                    for predictions. Select genus before species.
                  </p>
                  <p className="input-category-title"><b>Species.</b> Select from list. For species
                    not in the list, select “sp”.
                  </p>
                </div>
                <AutofillField instruction={instruction} />

                <div className="run-model-button-container">
                  <button
                    disabled={hosts.length === 0 || insectClimate.length === 0 || insectEcozone.length === 0 || !insectFamily || !insectFeedingGuild || !insectGenus || !insectOrder || !insectSpecies}
                    className="run-model-button button"
                    type="button"
                    onClick={onFormSubmit}
                  >Run Model
                  </button>
                </div>
                <p>If the model does not seem to run or seems to never finish,
                  please check your inputs and try again. If the situation
                  continues, please reach out to the <a
                    href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#97;&#110;&#103;&#101;&#108;&#97;&#46;&#109;&#101;&#99;&#104;&#64;&#109;&#97;&#105;&#110;&#101;&#46;&#101;&#100;&#117;"
                  >Biology and Ecology Department</a> at the University of
                  Maine with a screenshot of your inputs.
                </p>
              </div>
            </div>
            <div className="outputs-container">
              <ModelOutput />
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    </div>
  );
};

export default DataEntry;
