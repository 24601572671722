import React from 'react';
import SimpleDropdown from '../simple-dropdown';

// dropdown items will be years that range from 2020 to the current year
const firstYear = 2020;
const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - firstYear + 1 }, (v, i) => i + firstYear);
const yearOptions = years.map((y) => {
  return { key: y, value: y, text: y };
});

/**
 * @description React year-selection dropdown menu component for the Admin Portal Download Data container
 * @param {String} className css class name
 * @param {(int) => any} setYear function that is passed the integer value of the currently selected year
 */
const YearSelect = ({ className, setYear }) => {
  return (
    <SimpleDropdown
      className={className}
      placeholder="Year"
      options={yearOptions}
      setFunc={setYear}
    />
  );
};

export default YearSelect;
