import { combineReducers } from 'redux';

import AdminReducer from './admin';
import AppReducer from './app';
import ErrorReducer from './error';
import FormReducer from './form';
import OptionsReducer from './options';
import PredictionsReducer from './predictions';

const rootReducer = combineReducers({
  admin: AdminReducer,
  app: AppReducer,
  errors: ErrorReducer,
  form: FormReducer,
  options: OptionsReducer,
  predictions: PredictionsReducer,
});

export default rootReducer;
