import { connect } from 'react-redux';
import AutofillField from './component';

import {
  addTreeHost,
  fetchTreeHosts,
  removeTreeHost,
} from '../../../../state/actions';

import './style.scss';

const mapStateToProps = (state) => {
  const {
    form: {
      tree: {
        hosts,
      },
    },
    options: {
      tree: {
        genera: treeGenera,
        hosts: treeHosts,
      },
    },
  } = state;

  return {
    treeGenera,
    treeHosts,
    selectedHosts: hosts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTreeHost: (host) => {
      dispatch(addTreeHost(host));
    },
    fetchTreeHosts: (genus) => {
      dispatch(fetchTreeHosts(genus));
    },
    removeTreeHost: (host) => {
      dispatch(removeTreeHost(host));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutofillField);
