import React, { useEffect, useState } from 'react';
import { Card } from '../../components';

/**
 * @description React component for the Profile Information card of the Admin Portal screen
 * @param {Object} currentUser stores info about the currently logged in admin user
 * @param {Object} error represents error state variable
 * @param {() => any} onChange function called upon state variable changes
 * @param {(string? firstName, string? lastName, string? email, string? password) => any} submit takes state variables, called when buttons are pressed
 */
const ProfileInfo = ({
  currentUser,
  error,
  onChange = () => {},
  submit = () => {},
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // initializes profile info to currentUser's
  useEffect(() => {
    setFirstName(currentUser.first_name);
    setLastName(currentUser.last_name);
    setEmail(currentUser.email);
  }, [currentUser]);

  // calls onChange prop whenever a state variable is changed
  useEffect(onChange, [firstName, lastName, email, password]);

  // called by button press
  const onClick = () => {
    if (firstName) submit({ first_name: firstName });
    if (lastName) submit({ last_name: lastName });
    if (email) submit({ email });
    if (password) submit({ password });
  };

  return (
    <Card name="Profile Information" className="big-card">
      <div className="profile-container">
        <form>
          <label htmlFor="changeFirstName">
            First Name
            <div className="profile-text-box-container">
              <input
                className={`text-box ${error?.badFirstName ? 'text-box-error' : ''}`}
                id="changeFirstName"
                name="given-name"
                autoComplete="given-name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
          </label>
          <label htmlFor="changeLastName">
            Last Name
            <div className="profile-text-box-container">
              <input
                className={`text-box ${error?.badLastName ? 'text-box-error' : ''}`}
                id="changeLastName"
                name="family-name"
                autoComplete="family-name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
          </label>
        </form>

        <form>
          <label htmlFor="changeEmail">
            Email
            <div className="profile-text-box-container">
              <input
                className={`text-box ${error?.badEmail ? 'text-box-error' : ''}`}
                id="changeEmail"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </label>
          <label htmlFor="changePassword">
            Password
            <div className="profile-text-box-container">
              <input
                className={`text-box ${error?.badPassword ? 'text-box-error' : ''}`}
                id="changePassword"
                type="password"
                name="new-password"
                autoComplete="new-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </label>
        </form>
      </div>
      <div className="bottom-flex-container">
        <button
          type="button"
          className="primary-button"
          onClick={onClick}
        >
          Edit profile
        </button>
        <p id="error-message" className="error-text">{error ? error.text : null}</p>
      </div>
    </Card>
  );
};

export default ProfileInfo;
