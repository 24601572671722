import axios from 'axios';

const SUBROUTES = {
  ECOZONES: 'ecozones',
  FEEDING_GUILDS: 'feeding-guild',
  INSECT_FAMILIES: 'insect-ranks/family',
  INSECT_GENERA: 'insect-ranks/genus',
  INSECT_ORDERS: 'insect-ranks/order',
  TREE_HOSTS: 'native-hosts',
};

/**
 * @description action creator fetching ecozones data
 * @return {string[]} Array containing the names of ecozones
 */
export const fetchInsectEcozones = async () => {
  const url = `${global.API_URL}/${SUBROUTES.ECOZONES}`;
  const { data: { data } } = await axios.get(url);
  return Object.values(data).map(object => object.ecozones);
};

/**
 * @description action creator for fetching feeding guild data
 * @return {string[]} Array containing the names of feeding guild
 */
export const fetchInsectFeedingGuilds = async () => {
  const url = `${global.API_URL}/${SUBROUTES.FEEDING_GUILDS}`;
  const { data: { data } } = await axios.get(url);
  return Object.values(data).map(object => object.feeding_guild);
};

/**
 * @description action creator for fetching insect family
 * @return {string[]} Array containing the names of insect families
 */
export const fetchInsectFamilies = async () => {
  const url = `${global.API_URL}/${SUBROUTES.INSECT_FAMILIES}`;
  const { data: { data } } = await axios.get(url);
  return Object.values(data).map(object => object.insect_family);
};

/**
 * @description action creator for fetching insect genus
 * @return {string[]} Array containing the names of insect genus
 */
export const fetchInsectGenera = async () => {
  const url = `${global.API_URL}/${SUBROUTES.INSECT_GENERA}`;
  const { data: { data } } = await axios.get(url);
  return Object.values(data).map(object => object.insect_genus);
};

/**
 * @description action creator for fetching insect orders
 * @return {string[]} Array containing the names of insect orders
 */
export const fetchInsectOrders = async () => {
  const url = `${global.API_URL}/${SUBROUTES.INSECT_ORDERS}`;
  const { data: { data } } = await axios.get(url);
  return Object.values(data).map(object => object.insect_order);
};

/**
 * @description action creator for fetching species data
 * @param {string} genusName - name of genus type
 * @return {string[]} Array containing the names of species of the given genus type
 */
export const fetchTreeHosts = async (genusName) => {
  const url = `${global.API_URL}/${SUBROUTES.TREE_HOSTS}`;
  const COLUMN_NAME = 'genus';
  const params = { params: { column: COLUMN_NAME, value: genusName } };
  const { data: { data } } = await axios.get(url, params);
  return Object.values(data).map(object => object.species);
};

/**
 * @description action creator for fetching tree genera data
 * @return {string[]} Array containing the names tree genera
 */
export const fetchTreeGenera = async () => {
  const url = `${global.API_URL}/${SUBROUTES.TREE_HOSTS}`;
  const COLUMN_NAME = 'genus';
  const params = { params: { column: COLUMN_NAME } };
  const { data: { data } } = await axios.get(url, params);
  return [...new Set(data.map(object => object.genus))]; // Gets genus column and removes duplicates
};
