import { connect } from 'react-redux';
import {
  setInsectClimate,
  setInsectEcozone,
  setInsectFamily,
  setInsectFeedingGuild,
  setInsectGenus,
  setInsectSpecies,
  setInsectOrder,

} from '../../../../state/actions';

import DropDownItems from './component';

const mapStateToProps = (state) => {
  const {
    form: {
      insect: {
        climate,
        ecozone,
        family,
        feedingGuild,
        genus,
        order,
        species,
      },
    },
    options: {
      insect: {
        ecozones: insectEcozones,
        families: insectFamilies,
        feedingGuilds: insectFeedingGuilds,
        genera: insectGenera,
        orders: insectOrders,
      },
    },
  } = state;

  return {
    climate,
    ecozone,
    family,
    feedingGuild,
    genus,
    insectEcozones,
    insectFamilies,
    insectFeedingGuilds,
    insectGenera,
    insectOrders,
    order,
    species,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setInsectClimate: (climate, checked) => {
      dispatch(setInsectClimate(climate, checked));
    },
    setInsectEcozone: (ecozone, checked) => {
      dispatch(setInsectEcozone(ecozone, checked));
    },
    setInsectFamily: (family) => {
      dispatch(setInsectFamily(family));
    },
    setInsectFeedingGuild: (feedingGuild) => {
      dispatch(setInsectFeedingGuild(feedingGuild));
    },
    setInsectGenus: (genus) => {
      dispatch(setInsectGenus(genus));
    },
    setInsectOrder: (order) => {
      dispatch(setInsectOrder(order));
    },
    setInsectSpecies: (species) => {
      dispatch(setInsectSpecies(species));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropDownItems);
