import React, { useEffect } from 'react';
import * as d3 from 'd3';

import './style.scss';

const OutputHistogram = (props) => {
  const {
    activeTab,
    allFamilies,
    allNames,
    allOrders,
    predictionResults,
  } = props;

  useEffect(() => {
    const width = 500;
    const height = 500;
    const color = 'steelblue';
    const margin = ({
      top: 20, right: 20, bottom: 30, left: 40,
    });

    const data = predictionResults[activeTab].predictions
      .filter(prediction => prediction.combinedPR_prob !== 'NA')
      .map((op, i) => {
        return parseFloat(op.combinedPR_prob);
      });
    const bins = d3.bin().thresholds(15)(data);

    const svg = d3.select('.svg-canvas');
    svg.selectAll('*').remove();

    const x = d3.scaleLinear()
      .domain([0, 0.2])
      .range([margin.left, width - margin.right]);

    const xAxis = g => g
      .attr('transform', `translate(0,${height - margin.bottom - 10})`)
      .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
      .call(g1 => g1.append('text')
        .attr('x', 390)
        .attr('y', 30)
        .attr('fill', 'currentColor')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'end')
        .text('Probability of high impact from non-native insect'));

    const y = d3.scaleLinear()
      .domain([0, d3.max(bins, d => d.length)]).nice()
      .range([height - margin.bottom - 10, margin.top]);

    const yAxis = g => g
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(height / 40))
      .call(g1 => g1.select('.domain').remove())
      .call(g1 => g1.select('.tick:last-of-type text').clone()
        .attr('x', -250)
        .attr('y', -35)
        .attr('text-anchor', 'start')
        .attr('font-weight', 'bold')
        .attr('transform', 'rotate(-90)')
        .text('Number of North American tree species'));

    svg.append('g')
      .attr('fill', color)
      .selectAll('rect')
      .data(bins)
      .join('rect')
      .attr('x', d => x(d.x0) + 1)
      .attr('width', d => Math.max(0, x(d.x1) - x(d.x0) - 1))
      .attr('y', d => y(d.length))
      .attr('height', d => y(0) - y(d.length));

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    svg.append('g')
      .append('text')
      .attr('x', 250)
      .attr('y', 30)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .style('text-decoration', 'italic')
      .text(allNames[activeTab].split('_').join(' '));

    svg.append('g')
      .append('text')
      .attr('x', 250)
      .attr('y', 50)
      .attr('text-anchor', 'middle')
      .style('font-size', '16px')
      .style('text-decoration', 'italic')
      .text(`(${allOrders[activeTab]}: ${allFamilies[activeTab]})`);
  }, [activeTab]);


  return (
    <div className="chart">
      <svg className="svg-canvas" width="500px" height="500px" />
      <p className="least-one">Probability of at least 1 high impact: {parseFloat(predictionResults[activeTab].predictions[0].probAtLeastOne).toFixed(3)}</p>
    </div>
  );
};

export default OutputHistogram;
