import ActionTypes from './types';

/**
 * @description action creator adding a native host
 * @param {Array<string>} hosts -  native host
 */
export const addTreeHost = (host) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_TREE_HOST,
      payload: host,
    });
  };
};

/**
 * @description action creator deleting a native host
 * @param {Array<string>} hosts -  native host
 */
export const removeTreeHost = (host) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.REMOVE_TREE_HOST,
      payload: host,
    });
  };
};

/**
 * @description action creator for setting the current tab
 */
export const setActiveTab = (activeTab) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_TAB,
      payload: activeTab,
    });
  };
};

/**
 * @description action creator setting newest insect the model is run on
 * @param {string} insect -  the newest insect
 */
export const setAllInsects = (order, family, genusSpecies, feedingGuild, climate, ecozone, treeHosts) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_ALL_INSECTS,
      payload: {
        order,
        family,
        genusSpecies,
        feedingGuild,
        climate,
        ecozone,
        treeHosts,
      },
    });
  };
};

export const setAllUserInfo = (userInfo) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_ALL_USER_INFO,
      payload: userInfo,
    });
  };
};

/**
 * @description action creator setting Köppen Climate
 * @param {string} climate - Köppen Climate of insect
 */
export const setInsectClimate = (climate, checked) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_CLIMATE,
      payload: {
        climate,
        checked,
      },
    });
  };
};

/**
 * @description action creator setting insect ecozone
 * @param {string} ecozone -  native range or ecozone of the insect
 */
export const setInsectEcozone = (ecozone, checked) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_ECOZONE,
      payload: {
        ecozone,
        checked,
      },
    });
  };
};

/**
 * @description action creator setting insect's family
 * @param {string} family -  family of insect
 */
export const setInsectFamily = (family) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_FAMILY,
      payload: family,
    });
  };
};

/**
 * @description action creator setting insect's feeding guild
 * @param {string} feedingGuild -  feeding guild of insect
 */
export const setInsectFeedingGuild = (feedingGuild) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_FEEDING_GUILD,
      payload: feedingGuild,
    });
  };
};

/**
 * @description action creator setting insect's genus
 * @param {string} genus -  genus of insect
 */
export const setInsectGenus = (genus) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_GENUS,
      payload: genus,
    });
  };
};

/**
 * @description action creator setting insect's order
 * @param {string} order -  order of insect
 */
export const setInsectOrder = (order) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_ORDER,
      payload: order,
    });
  };
};

/**
 * @description action creator setting insect's species
 * @param {string} species - species of insect
 */
export const setInsectSpecies = (species) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INSECT_SPECIES,
      payload: species,
    });
  };
};

/**
 * @description action creator setting user's email
 * @param {string} email -  email of user
 */
export const setUserEmail = (email) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_USER_EMAIL,
      payload: email,
    });
  };
};

/**
 * @description action creator setting user's employer
 * @param {string} employer -  employer of user
 */
export const setUserEmployer = (employer) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_USER_EMPLOYER,
      payload: employer,
    });
  };
};

/**
 * @description action creator setting user's name
 * @param {string} name -  name of user
 */
export const setUserName = (name) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_USER_NAME,
      payload: name,
    });
  };
};

/**
 * @description action creator setting user's occupation
 * @param {string} occupation -  occupation of user
 */
export const setUserOccupation = (occupation) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_USER_OCCUPATION,
      payload: occupation,
    });
  };
};
