/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'semantic-ui-react';

import { MEDIAN_IMPACT_PROBABILITY } from '../../../../../../constants';

import './style.scss';


const OutputTable = (props) => {
  const {
    activeTab,
    predictionResults,
  } = props;

  const likelihoodvals = { High: 0, Medium: 1, Low: 2 };

  // eslint-disable-next-line max-len
  const gradient = ['#0023F2', '#2735C9', '#4248B3', '#5D609B', '#757587', '#8C8C75', '#A09F66', '#BDBB57', '#D7D550', '#F3F050', '#F3F050', '#FCE94E', '#F7C644', '#F4B13E', '#F19637', '#F08432', '#ED682C', '#EC5528', '#EC4526', '#E83323'];

  const calculateColor = (val) => {
    let index = '';
    if (val < MEDIAN_IMPACT_PROBABILITY) {
      index = parseInt(val / (MEDIAN_IMPACT_PROBABILITY / 10), 10);
    } else if (val < 0.2) {
      index = parseInt((val / ((0.2 - MEDIAN_IMPACT_PROBABILITY) / 10)), 10) + 10;
    } else {
      index = gradient.length - 1;
    }
    return gradient[index];
  };

  const renderTreeName = (davey, powo) => {
    if (davey !== 'ND') {
      return davey.replace(/_/g, ' ');
    } else {
      return powo.replace(/_/g, ' ');
    }
  };

  const tableData = predictionResults[activeTab].predictions
    .filter(prediction => prediction.combinedPR_prob !== 'NA')
    .sort((a, b) => likelihoodvals[a.infile.treeLikelihood] - likelihoodvals[b.infile.treeLikelihood] || b.combinedPR_prob - a.combinedPR_prob);

  return (
    <div className="output-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tree Species</Table.HeaderCell>
            <Table.HeaderCell>Host Suitability</Table.HeaderCell>
            <Table.HeaderCell
              colSpan={2}
            >
              Probability of High Impact
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData
            .map((result, i) => {
              if (Number(result.combinedPR_prob).toFixed(3) > 0.001) {
                return (
                  <Table.Row>
                    <Table.Cell
                      width="eight"
                      className={result.climateMatch === '0' ? 'shaded tree-species' : 'tree-species'}
                    >{renderTreeName(result.infile.NA_Host_Davey, result.infile.NA_Host_POWO)}
                    </Table.Cell>
                    <Table.Cell className={`${result.infile.treeLikelihood.toLowerCase()}-background`}>{result.infile.treeLikelihood}</Table.Cell>
                    <Table.Cell>{Number(result.combinedPR_prob).toFixed(3)}</Table.Cell>
                    <Table.Cell style={{ backgroundColor: `${calculateColor(result.combinedPR_prob)}` }} width="one" />
                  </Table.Row>
                );
              } else {
                return (
                  <Table.Row>
                    <Table.Cell width="eight" className="tree-species">{renderTreeName(result.infile.NA_Host_Davey, result.infile.NA_Host_POWO)}</Table.Cell>
                    <Table.Cell className={`${result.infile.treeLikelihood.toLowerCase()}-background`}>{result.infile.treeLikelihood}</Table.Cell>
                    <Table.Cell>&lt;0.001</Table.Cell>
                    <Table.Cell style={{ backgroundColor: `${calculateColor(result.combinedPR_prob)}` }} width="one" />
                  </Table.Row>
                );
              }
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default OutputTable;
