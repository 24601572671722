import { ActionTypes } from '../actions';

const initialState = {
  insect: {
    ecozones: [],
    families: [],
    feedingGuilds: [],
    genera: [],
    orders: [],
  },
  tree: {
    genera: [],
    hosts: [],
  },
};

const OptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_INSECT_ECOZONES:
      return { ...state, insect: { ...state.insect, ecozones: action.payload } };
    case ActionTypes.FETCH_INSECT_FAMILIES:
      return { ...state, insect: { ...state.insect, families: action.payload } };
    case ActionTypes.FETCH_INSECT_FEEDING_GUILDS:
      return { ...state, insect: { ...state.insect, feedingGuilds: action.payload } };
    case ActionTypes.FETCH_INSECT_GENERA:
      return { ...state, insect: { ...state.insect, genera: action.payload } };
    case ActionTypes.FETCH_INSECT_ORDERS:
      return { ...state, insect: { ...state.insect, orders: action.payload } };
    case ActionTypes.FETCH_TREE_GENERA:
      return { ...state, tree: { ...state.tree, genera: action.payload } };
    case ActionTypes.FETCH_TREE_HOSTS:
      return { ...state, tree: { ...state.tree, hosts: action.payload } };
    default:
      return state;
  }
};

export default OptionsReducer;
