import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import {
  fetchInsectEcozones,
  fetchInsectFamilies,
  fetchInsectFeedingGuilds,
  fetchInsectGenera,
  fetchInsectOrders,
  fetchTreeGenera,
  getAllAdminUsers,
  getAdminUserInfo,
  setSocket,
} from './state/actions';

import {
  Admin,
  Home,
  DataEntry,
  Resources,
  Science,
  PublicationsAndCode,
  Team,
} from './screens';

import {
  ScrollToTop,
} from './components';

import {
  getServerUrl,
  LOCAL_STORAGE_KEYS,
  ROUTES,
} from './constants';

import {
  getLocal,
} from './utils';

const FallBack = () => {
  return <div>URL not found</div>;
};

const App = (props) => {
  useEffect(() => {
    global.API_URL = getServerUrl();

    props.fetchInsectEcozones();
    props.fetchInsectFamilies();
    props.fetchInsectFeedingGuilds();
    props.fetchInsectGenera();
    props.fetchInsectOrders();
    props.fetchTreeGenera();

    // initialize socket connection
    const socket = io(global.API_URL);
    props.setSocket(socket);
  }, []);

  useEffect(() => {
    const adminToken = getLocal(LOCAL_STORAGE_KEYS.admin.token);
    const adminUsername = getLocal(LOCAL_STORAGE_KEYS.admin.username);

    if (adminToken && adminUsername && Object.keys(props.currentUser).length === 0) {
      props.getAdminUserInfo(adminUsername);
    } else if (adminToken && adminUsername) {
      props.getAllAdminUsers();
    }
  }, [props.currentUser]);

  return (
    <Router>
      <ScrollToTop>
        <div>
          {/* <Header /> */}
          <div className="content">
            <Switch>
              <Route exact path={ROUTES.HOME} component={Home} />
              <Route path={ROUTES.ADMIN} component={Admin} />
              <Route path={ROUTES.DATA_ENTRY} component={DataEntry} />
              <Route path={ROUTES.RESOURCES} component={Resources} />
              <Route path={ROUTES.SCIENCE} component={Science} />
              <Route path={ROUTES.PUBLICATIONS_AND_CODE} component={PublicationsAndCode} />
              <Route path={ROUTES.TEAM} component={Team} />
              <Route component={FallBack} />
            </Switch>
          </div>
          {/* <Footer /> */}
        </div>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = (state) => {
  const {
    admin: {
      currentUser,
    },
  } = state;

  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInsectEcozones: () => {
      dispatch(fetchInsectEcozones);
    },
    fetchInsectFeedingGuilds: () => {
      dispatch(fetchInsectFeedingGuilds);
    },
    fetchInsectGenera: () => {
      dispatch(fetchInsectGenera);
    },
    fetchInsectFamilies: () => {
      dispatch(fetchInsectFamilies);
    },
    fetchInsectOrders: () => {
      dispatch(fetchInsectOrders);
    },
    fetchTreeGenera: () => {
      dispatch(fetchTreeGenera);
    },
    getAllAdminUsers: () => {
      dispatch(getAllAdminUsers);
    },
    getAdminUserInfo: (username) => {
      dispatch(getAdminUserInfo(username));
    },
    setSocket: (socket) => {
      dispatch(setSocket(socket));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
