import React, { useState } from 'react';
import './style.scss';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { getLocal } from '../../utils';

import {
  AddAdmin,
  AdminList,
  BatchRun,
  ConfirmDelete,
  DownloadData,
  ForgotPassword,
  LoginAdmin,
  ProfileInfo,
} from './cards';

const Admin = (props) => {
  const {
    addAdminError,
    addAdminSuccess,
    adminError,
    allAdminUsers,
    currentUser,
    clearAdminErrors,
    deleteAdminUser,
    downloadOutputDataError,
    downloadOutputData,
    editAdminError,
    editAdminUser,
    loginUser,
    loginUserError,
    logoutUser,
    registerAdminUser,
    resetPassword,
    resetPasswordError,
    resetPasswordSuccess,
  } = props;

  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [confirmDeleteUsername, setConfirmDeleteUsername] = useState('');
  const showConfirmDelete = (username) => {
    setConfirmDeleteUsername(username);
    setConfirmDeleteVisible(true);
  };
  const isAuthed = (getLocal(LOCAL_STORAGE_KEYS.admin.token) && Object.keys(currentUser).length > 0);

  return (
    <div className="root">
      <div className="scroll-container">
        {isAuthed
          ? (
            <div>
              <div className="right-container">
                <div className="signout-container">
                  <button
                    type="button"
                    className="signout-button"
                    onClick={logoutUser}
                  >
                    Sign out
                  </button>
                </div>
              </div>
              <div className="flex-container">
                <div className="card-column">
                  <AddAdmin
                    error={addAdminError}
                    onChange={clearAdminErrors}
                    submit={registerAdminUser}
                    success={addAdminSuccess}
                  />
                  <DownloadData
                    download={downloadOutputData}
                    error={downloadOutputDataError}
                    onChange={clearAdminErrors}
                  />
                </div>
                <div className="card-column">
                  <AdminList
                    data={allAdminUsers}
                    error={adminError}
                    onClickButton={showConfirmDelete}
                  />
                  <ConfirmDelete
                    hide={() => setConfirmDeleteVisible(false)}
                    submit={deleteAdminUser}
                    username={confirmDeleteUsername}
                    visible={confirmDeleteVisible}
                  />
                  <ProfileInfo
                    currentUser={currentUser}
                    onChange={clearAdminErrors}
                    submit={editAdminUser}
                    error={editAdminError}
                  />
                  <BatchRun />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-container">
              {(forgotPasswordVisible)
                ? (
                  <ForgotPassword
                    error={resetPasswordError}
                    onChange={clearAdminErrors}
                    onClickBack={() => setForgotPasswordVisible(false)}
                    submit={resetPassword}
                    success={resetPasswordSuccess}
                  />
                ) : (
                  <LoginAdmin
                    error={loginUserError}
                    onChange={clearAdminErrors}
                    onClickForgotPassword={() => setForgotPasswordVisible(true)}
                    submit={loginUser}
                  />
                )}
            </div>
          )}
      </div>
    </div>
  );
};

export default Admin;
