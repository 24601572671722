const clearActions = {
  CLEAR_ADMIN_DATA: 'CLEAR_ADMIN_DATA',
  CLEAR_ADMIN_ERRORS: 'CLEAR_ADMIN_ERRORS',
  CLEAR_OPTIONS_ERROR: 'CLEAR_OPTIONS_ERROR',
  CLEAR_PREDICTION_UPDATES: 'CLEAR_PREDICTION_UPDATES',
};

const fetchActions = {
  FETCH_INSECT_ECOZONES: 'FETCH_INSECT_ECOZONES',
  FETCH_INSECT_FAMILIES: 'FETCH_INSECT_FAMILIES',
  FETCH_INSECT_FEEDING_GUILDS: 'FETCH_INSECT_FEEDING_GUILDS',
  FETCH_INSECT_GENERA: 'FETCH_INSECT_GENERA',
  FETCH_INSECT_ORDERS: 'FETCH_INSECT_ORDERS',
  FETCH_PREDICTION_RESULTS: 'FETCH_PREDICTION_RESULTS',
  FETCH_PREDICTION_UPDATE: 'FETCH_PREDICTION_UPDATE',
  FETCH_TREE_GENERA: 'FETCH_TREE_GENERA',
  FETCH_TREE_HOSTS: 'FETCH_TREE_HOSTS',
};

const setActions = {
  ADD_TREE_HOST: 'ADD_TREE_HOST',
  REMOVE_TREE_HOST: 'REMOVE_TREE_HOST',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_ADMIN_ADD_ERROR: 'SET_ADMIN_ADD_ERROR',
  SET_ADMIN_ADD_SUCCESS: 'SET_ADMIN_ADD_SUCCESS',
  SET_ADMIN_EDIT_ERROR: 'SET_ADMIN_EDIT_ERROR',
  SET_ADMIN_ERROR: 'SET_ADMIN_ERROR',
  SET_ADMIN_DOWNLOAD_ERROR: 'SET_ADMIN_DOWNLOAD_ERROR',
  SET_ADMIN_LOGIN_ERROR: 'SET_ADMIN_LOGIN_ERROR',
  SET_ADMIN_USER_INFO: 'SET_ADMIN_USER_INFO',
  SET_ALL_ADMIN_USERS: 'SET_ALL_ADMIN_USERS',
  SET_ALL_INSECTS: 'SET_ALL_INSECTS',
  SET_ALL_USER_INFO: 'SET_ALL_USER_INFO',
  SET_INSECT_CLIMATE: 'SET_INSECT_CLIMATE',
  SET_INSECT_ECOZONE: 'SET_INSECT_ECOZONE',
  SET_INSECT_FAMILY: 'SET_INSECT_FAMILY',
  SET_INSECT_FEEDING_GUILD: 'SET_INSECT_FEEDING_GUILD',
  SET_INSECT_GENUS: 'SET_INSECT_GENUS',
  SET_INSECT_ORDER: 'SET_INSECT_ORDER',
  SET_INSECT_SPECIES: 'SET_INSECT_SPECIES',
  SET_MODEL_RUNNING: 'SET_MODEL_RUNNING',
  SET_OPTIONS_ERROR: 'SET_OPTIONS_ERROR',
  SET_SOCKET: 'SET_SOCKET',
  SET_TREE_HOSTS: 'SET_TREE_HOSTS:',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_USER_EMPLOYER: 'SET_USER_EMPLOYER',
  SET_USER_NAME: 'SET_NAME',
  SET_USER_OCCUPATION: 'SET_USER_OCCUPATION',
  SET_RESET_PASSWORD_ERROR: 'SET_RESET_PASSWORD_ERROR',
  SET_RESET_PASSWORD_SUCCESS: 'SET_RESET_PASSWORD_SUCCESS',
};

const ActionTypes = {
  ...clearActions,
  ...fetchActions,
  ...setActions,
};

export default ActionTypes;
