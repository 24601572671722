import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { HeaderMenu, FooterMenu } from '../../components';
import './style.scss';
import treeLogo from '../../assets/icons/i-tree.svg';

const Home = (_props) => {
  return (
    <div>
      <HeaderMenu />
      <div className="home-root">
        <div className="home-flex-container">
          <div className="home-left-column">
            <h1 className="home-title">Welcome to the i-Tree Pest Predictor Tool!</h1>
            <p className="home-lead">
              How do we know whether a non-native insect species will become invasive?
            </p>
            <p className="home-text">
              Many species arrive and never establish, but a few that do can have devastating
              impacts and threaten entire species of trees. This tool predicts the probability
              that a newly-arriving insect species will become a high impact invader on 50+ conifer
              species and 360+ hardwood species native to North America. Enter basic information
              on the insect of interest, including information on insect taxonomy, feeding guild,
              native range and native climate, and a list of tree hosts. The tool will then provide
              a list of probabilities for each relevant North American host tree.
            </p>
            <Link className="home-button-text-container" to={ROUTES.SCIENCE}>
              <p className="home-button-text-underline">Read the science behind the tool</p>
              <p>&#10230;</p>
            </Link>
          </div>
          <div className="home-right-column">
            <img className="home-tree-logo" src={treeLogo} alt="i-Tree" />
            <Link className="home-button-primary" to={ROUTES.DATA_ENTRY}>
              <i className="power off icon" />
              Get Started
            </Link>
            <div className="eula">Use of this tool indicates acceptance of the <a href="https://help.itreetools.org/eula/?embed=true">EULA</a>.</div>
          </div>
        </div>
        <FooterMenu />
      </div>
    </div>
  );
};

export default Home;
