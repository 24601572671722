import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

/**
 * @description React component for the confirm delete admin modal of the Admin Portal screen
 * @param {() => any} hide function called on back button press
 * @param {(string username) => any} submit takes state variable, called when button is pressed
 * @param {string} username represents state variable of admin username to be deleted
 * @param {boolean} visible determines whether the modal is visible
 */
const ConfirmDelete = ({
  hide = () => {},
  submit = () => {},
  username,
  visible,
}) => {
  return (
    <Rodal visible={visible} onClose={hide} showCloseButton={false} width={530}>
      <div className="modal">
        <p className="bold-text">Are you sure you want to delete {username} as an admin?</p>
        <p className="normal-text">
          This action will remove this person from the admin list and permanently delete this person&#39;s existing admin account.
          A new admin account will have to be made for this person if they are re-added as an admin.
        </p>
        <div className="buttons-container">
          <button type="button" className="primary-button" onClick={hide}>No, go back</button>
          <button
            type="button"
            className="primary-button"
            onClick={() => {
              submit(username);
              hide();
            }}
            style={{ backgroundColor: '#FF8686' }}
          >
            Yes, delete admin
          </button>
        </div>
      </div>
    </Rodal>
  );
};

export default ConfirmDelete;
