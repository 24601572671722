import React from 'react';
import TableHeadItem from './components/table-head-item';
import TableRow from './components/table-row';

const headers = ['First Name', 'Last Name', 'Username', 'Email', 'Actions'];

/**
 * @description React table body component for the Admin Portal Admin List container
 * @param {Object[]} data array that holds objects of table body row items and row id
 * @param {(string) => any} onClickButton function that is passed the item.username value of the a row's item
 */
const Table = ({ data, onClickButton }) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map((h) => {
            return <TableHeadItem key={h} item={h} />;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return <TableRow key={item.id} data={item} onClickButton={onClickButton} />;
        })}
      </tbody>
    </table>
  );
};

export default Table;
