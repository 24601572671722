import React from 'react';
import { Dropdown } from 'semantic-ui-react';

/**
 * @description React dropdown menu component for the Admin Portal screen
 * @param {String} className css class name
 * @param {String} placeholder placeholder text displayed when no item is selected
 * @param {Object[]} options Array of Dropdown.Item props for dropdown content e.g. { key: '', text: '', value: '' }
 * @param {(any) => any} setFunc function that is passed the value of the currently selected Dropdown.Item
 */
const SimpleDropdown = ({
  className,
  placeholder,
  options,
  setFunc = () => {},
}) => {
  return (
    <Dropdown
      className={className}
      placeholder={placeholder}
      fluid
      scrolling
      selection
      clearable
      search
      options={options}
      onChange={(event, selected) => setFunc(selected.value)}
    />
  );
};

export default SimpleDropdown;
