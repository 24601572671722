/**
 * @description retrieves key from local storage
 * @returns {String} value in local storage
 */
export const getLocal = key => localStorage.getItem(key);

/**
 * @description sets key in local storage
 */
export const setLocal = (key, value) => localStorage.setItem(key, value);

/**
 * @description removes key from local storage
 * @returns {String} value in local storage
 */
export const removeLocal = key => localStorage.removeItem(key);
