/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { OPTIONS } from '../../../../constants';

import KoppenClimate from '../../../../assets/koppen-climate.svg';
import NativeRange from '../../../../assets/native-range.svg';

import './style.scss';

const DropDownItems = (props) => {
  const {
    climate,
    ecozone,
    family,
    feedingGuild,
    genus,
    insectEcozones,
    insectFamilies,
    insectFeedingGuilds,
    insectGenera,
    insectOrders,
    order,
    setInsectClimate,
    setInsectEcozone,
    setInsectFamily,
    setInsectFeedingGuild,
    setInsectGenus,
    setInsectOrder,
    setInsectSpecies,
    showInstruction,
    species,
  } = props;

  const INPUT_INFORMATION = [
    {
      id: 'insect-order',
      text: 'Order',
      options: insectOrders,
      value: order,
      valueSetter: setInsectOrder,
    },
    {
      id: 'insect-family',
      instruction: 'Family <br /> Please select the taxonomic family of the herbivorous insect',
      text: 'Family',
      options: insectFamilies,
      value: family,
      valueSetter: setInsectFamily,
    },
    {
      id: 'insect-genus-species',
      text: ['Genus', 'Species'],
      options: insectGenera,
      value: [genus, species],
      valueSetter: [setInsectGenus, setInsectSpecies],
    },
    {
      id: 'feeding-guild',
      text: 'Insect Feeding Guild',
      options: insectFeedingGuilds,
      value: feedingGuild,
      valueSetter: setInsectFeedingGuild,
    },
    {
      id: 'koppen-climate',
      text: 'Köppen Climate (click all that apply)',
      options: OPTIONS.CLIMATES,
      value: climate,
      valueSetter: setInsectClimate,
    },
    {
      id: 'native-range',
      text: 'Native Range (click all that apply)',
      options: insectEcozones,
      value: ecozone,
      valueSetter: setInsectEcozone,
    },
  ];

  const dropdownStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#F2F2F2' : null,
      color: '#000000',
    }),
    control: base => ({
      ...base,
      fontFamily: 'Mulish',
      fontSize: '14px',
      background: '#F4F5F4',
      border: '1x solid',
      borderColor: '#A0A4A8',
      borderRadius: '5px',
    }),
  };

  return (
    <div className="drop-down">
      {INPUT_INFORMATION.map((inputInfo) => {
        const {
          id,
          instruction,
          text,
          options,
          value,
          valueSetter,
        } = inputInfo;

        const allOptions = options
          .sort()
          .map((op) => {
            return { value: op, label: op };
          });
        if (id === 'insect-order' || id === 'insect-family') {
          return (
            <div className="basic-user-input" key={id}>
              <p className="input-category-title">{text}</p>
              <a
                data-tip
                data-for={`${text}`}
              >
                <div>
                  <Select
                    blurInputOnSelect
                    onChange={(event) => { valueSetter(event.value); }}
                    options={allOptions}
                    placeholder="- Select or Start Typing -"
                    value={value === '' ? null : { label: value, value }}
                    styles={dropdownStyles}
                  />
                </div>
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id={`${text}`}
                    multiline
                    place="right"
                    textColor="#737B7D"
                  >
                    <p><b>Insect {text}</b></p>
                    <p>Please select the taxonomic {text.toLowerCase()} of the herbivorous insect</p>
                  </ReactTooltip>
                ) : null}
            </div>
          );
        } else if (id === 'insect-genus-species') {
          return (
            <div>
              <a
                data-tip
                data-for="genus-species"
              >
                <div className="genus-species-row">
                  <div className="basic-user-input insect-genus" key="insect-genus">
                    <p className="input-category-title">{text[0]}</p>
                    <CreatableSelect
                      blurInputOnSelect
                      onChange={(event) => { valueSetter[0](event.value); }}
                      options={allOptions}
                      placeholder="- Select or Start Typing -"
                      value={value[0] === '' ? null : { label: value[0], value: value[0] }}
                      styles={dropdownStyles}
                    />
                  </div>
                  <div className="basic-user-input insect-species" key="insect-species">
                    <p className="input-category-title">{text[1]}</p>
                    <input
                      className="input-dropdown insect-species-input"
                      id="insect-species"
                      onChange={event => valueSetter[1](event.target.value)}
                      value={value[1]}
                      placeholder="- Start Typing -"
                    />
                  </div>
                </div>
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id="genus-species"
                    multiline
                    place="right"
                    textColor="#737B7D"
                  >
                    <p><b>Genus and Species</b></p>
                    <p>Species name is any text, but genus must be selected from the list</p>
                  </ReactTooltip>
                ) : null}
            </div>
          );
        } else if (id === 'feeding-guild') {
          return (
            <div className="basic-user-input" key={id}>
              <p className="input-category-title">{text}</p>
              <a
                data-tip
                data-for={`${text}`}
              >
                <Select
                  blurInputOnSelect
                  onChange={(event) => { valueSetter(event.value); }}
                  options={allOptions}
                  placeholder="- Select or Start Typing -"
                  value={value === '' ? null : { label: value, value }}
                  styles={dropdownStyles}
                />
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id={`${text}`}
                    multiline
                    place="right"
                    textColor="#737B7D"
                  >
                    <p><b>Feeding Guild</b></p>
                    <p>Please select the primary feeding guild of the insect. If the life stages feed differently </p>
                    <p>(e.g. larva feed on roots, adults feed on leaves), select the guild that is most damaging to the host.</p>
                    <p><b>Folivores</b> includes leaf feeders, leaf miners, leaf rollers, and shoot/tip feeders</p>
                    <p><b>Reproductive Plant Feeders</b> includes seed, cone, fruit, inflorescence feeders, etc.</p>
                    <p><b>Sap Feeders</b> include insects that have piercing sucking mouthparts for extracting sap from the host (from leaves, branches, bole, etc.)</p>
                    <p><b>Wood/Stem Feeders</b> include insects that bore into the host and feed under the bark; subcortical</p>
                  </ReactTooltip>
                ) : null}
            </div>
          );
        } else if (id === 'koppen-climate') {
          return (
            <div className="basic-user-input" key={id}>
              <p className="input-category-title">{text}</p>
              <a
                data-tip
                data-for={`${id}`}
              >
                <div className="checkbox-container-grid input-dropdown">
                  {options.map((op) => {
                    return (
                      <div className="checkbox-container" key={op}>
                        <input
                          id={op}
                          checked={value[op.toLowerCase()] === 1}
                          type="checkbox"
                          className="checkbox"
                          onChange={(event) => { valueSetter(op, event.target.checked); }}
                        />
                        <label htmlFor={op} id={op}>
                          {op}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id={`${id}`}
                    multiline
                    place="right"
                    textColor="#737B7D"
                  >
                    <p><b>Köppen Climate</b></p>
                    <p>Please indicate the insect’s native climate. Select all that apply</p>
                    <img src={KoppenClimate} alt="map with colored regions for köppen climate" />
                  </ReactTooltip>
                ) : null}
            </div>
          );
        } else if (id === 'native-range') {
          return (
            <div className="basic-user-input" key={id}>
              <p className="input-category-title">{text}</p>
              <a
                data-tip
                data-for={`${id}`}
              >
                <div className="checkbox-container-grid input-dropdown">
                  {options.map((op) => {
                    return (
                      <div className="checkbox-container" key={op}>
                        <input
                          id={op}
                          checked={value[op.toLowerCase()] === 1}
                          type="checkbox"
                          className="checkbox"
                          onChange={(event) => { valueSetter(op, event.target.checked); }}
                        />
                        <label htmlFor={op} id={op}>
                          {op}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id={`${id}`}
                    multiline
                    place="right"
                    textColor="#737B7D"
                  >
                    <p><b>Native Range</b></p>
                    <p>Please indicate the insect’s native area. Select all that apply</p>
                    <img src={NativeRange} alt="map with colored regions for native range" />
                  </ReactTooltip>
                ) : null}
            </div>
          );
        } else {
          return (
            <div className="basic-user-input" key={id}>
              <p className="input-category-title">{text}</p>
              <a
                data-tip={instruction}
                data-for={`${text}`}
              >
                <Select
                  blurInputOnSelect
                  onChange={(event) => { valueSetter(event.value); }}
                  options={allOptions}
                  placeholder="- Select or Start Typing -"
                  value={value === '' ? null : { label: value, value }}
                  styles={dropdownStyles}
                />
              </a>
              {showInstruction
                ? (
                  <ReactTooltip
                    arrowColor="white"
                    className="tooltips"
                    border
                    borderColor="#517ED0"
                    effect="solid"
                    id={`${text}`}
                    multiline
                    place="right"
                    textColor="#737B7D"
                  />
                ) : null}
            </div>
          );
        }
      })}
    </div>
  );
};

export default DropDownItems;
