import { connect } from 'react-redux';

import Admin from './component';

import {
  clearAdminErrors,
  deleteAdminUser,
  downloadOutputData,
  editAdminUser,
  loginUser,
  logoutUser,
  registerAdminUser,
  resetPassword,
} from '../../state/actions';

const mapStateToProps = (state) => {
  const {
    admin: {
      adminError,
      addAdminError,
      addAdminSuccess,
      allAdminUsers,
      currentUser,
      downloadOutputDataError,
      editAdminError,
      loginUserError,
      resetPasswordError,
      resetPasswordSuccess,
    },
  } = state;

  return {
    adminError,
    addAdminError,
    addAdminSuccess,
    allAdminUsers,
    currentUser,
    downloadOutputDataError,
    editAdminError,
    loginUserError,
    resetPasswordError,
    resetPasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAdminErrors: () => {
      dispatch(clearAdminErrors());
    },
    deleteAdminUser: (username) => {
      dispatch(deleteAdminUser(username));
    },
    downloadOutputData: (fileName, month, year) => {
      dispatch(downloadOutputData(fileName, month, year));
    },
    editAdminUser: (fields) => {
      dispatch(editAdminUser(fields));
    },
    loginUser: (username, password) => {
      dispatch(loginUser(username, password));
    },
    logoutUser: () => {
      dispatch(logoutUser());
    },
    registerAdminUser: (username, password, firstName, lastName, email) => {
      dispatch(registerAdminUser(username, password, firstName, lastName, email));
    },
    resetPassword: (email) => {
      dispatch(resetPassword(email));
    }, // action for clearing error
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admin);
