import OPTIONS from './options';

const SERVER_ENDPOINTS = {
  OLD_PROD: 'https://insect-invasion.herokuapp.com',
  OLD_DEV: 'https://insect-invasion-dev.herokuapp.com',
  OLD_LOCAL: 'http://localhost:9090',
  PROD: 'https://api.pestpredictor.itreetools.org',
  DEV: 'https://dev.api.pestpredictor.itreetools.org',
  LOCAL: 'http://localhost:9000',
};

const getServerUrl = () => {
  switch (window.location.origin) {
    case 'https://pestpredictor.itreetools.org':
    case 'https://insect-invasion.netlify.app':
      return SERVER_ENDPOINTS.PROD;

    case 'https://dev.pestpredictor.itreetools.org':
    case 'https://insect-invasion-dev.netlify.app':
      return SERVER_ENDPOINTS.DEV;

    case 'http://localhost:8000':
    case 'http://localhost:8080':
      return SERVER_ENDPOINTS.LOCAL;

    default:
      // Attempts to allow non-localhost internal IP address use.
      return window.location.origin.replace('8000', '9000');
  }
};

const ROUTES = {
  ABOUT: '/about',
  ADMIN: '/admin',
  DATA_ENTRY: '/predictions',
  HOME: '/',
  RESOURCES: '/resources',
  SCIENCE: '/science',
  PUBLICATIONS_AND_CODE: '/publications-and-code',
  TEAM: '/team',
};

const SocketActions = {
  CONNECTION: 'connection',
  MODEL_COMPLETED: 'model_completed',
  MODEL_STATUS_UPDATE: 'model_status_update',
  REQUEST_MODEL_RUN: 'request_model_run',
  STATUS_MESSAGE: 'status_message',
};

const LOCAL_STORAGE_KEYS = {
  admin: {
    username: 'IIM_DALI_admin_username',
    token: 'IIM_DALI_admin_token',
  },
};

// median probability for the risk of an insect becoming invasive
// used to determine gradient coloring for table output
const MEDIAN_IMPACT_PROBABILITY = 0.025;

export {
  getServerUrl,
  LOCAL_STORAGE_KEYS,
  MEDIAN_IMPACT_PROBABILITY,
  OPTIONS,
  ROUTES,
  SERVER_ENDPOINTS,
  SocketActions,
};
