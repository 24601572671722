import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import reducers from './state/reducers';
import App from './app';
import './style.scss';

// create store with reducers + intialize redux dev tools
const store = createStore(reducers, {}, composeWithDevTools(
  applyMiddleware(thunk),
));

// wrap app in provider
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('main'),
);
