import React, { useEffect, useState } from 'react';
import { Card } from '../../components';
import logo from '../../../../assets/icons/i-tree.svg';

/**
 * @description React component for the login card of the Admin Portal screen
 * @param {Object} error represents error state variable
 * @param {() => any} onChange function called upon state variable changes
 * @param {() => any} onClickForgotPassword function called upon forgot password button press
 * @param {(string username, string password) => any} submit takes state variables, called when button is pressed
 */
const LoginAdmin = ({
  error,
  onChange = () => {},
  onClickForgotPassword = () => {},
  submit = () => {},
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // calls onChange prop whenever a state variable is changed
  useEffect(onChange, [username, password]);

  // called by Login button press
  const onClickLogin = () => submit(username, password);

  return (
    <Card className="login-card">
      <img className="logo" src={logo} alt="i-Tree" />
      <div className="card-title-box">
        <h1 className="card-title">Pest Predict</h1>
        <h2 className="card-subtitle">Admin Portal</h2>
      </div>
      <form>
        <label htmlFor="loginUsername">
          Username
          <input
            className={`text-box ${error?.badUsername ? 'text-box-error' : ''}`}
            id="loginUsername"
            name="username"
            autoComplete="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </label>
        <label htmlFor="loginPass">
          Password
          <input
            className={`text-box ${error?.badPassword ? 'text-box-error' : ''}`}
            id="loginPass"
            type="password"
            name="current-password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <input className="text-button" style={{ textAlign: 'right' }} type="button" value="Forgot password?" onClick={onClickForgotPassword} />
      </form>
      <div className="bottom-flex-container">
        <button type="button" className="primary-button" onClick={onClickLogin}>Login</button>
        {error ? <p id="error-message" className="error-text">{error.text}</p> : null}
      </div>
    </Card>
  );
};

export default LoginAdmin;
